export default function($t) {
  return [
    {
      name: 'status',
      type: 'portal',
      is_visible: true,
      get short_title() {
        return $t('Inventories.inventoryStatus')
      },
      get title() {
        return $t('Inventories.inventoryStatus')

      }
    },
    {
      name: 'storeName',
      is_visible: true,
      headerTitle: true,
      no_tooltip: true,
      get short_title() {
        return $t('Inventories.inventoryStoreName')
      },
      get title() {
        return $t('Inventories.inventoryStoreName')
      }
    },
    {
      name: 'inventoryDate',
      type: 'date',
      is_visible: true,
      get short_title() {
        return $t('Inventories.inventoryCreateDate')
      },
      get title() {
        return $t('Inventories.inventoryCreateDate')
      },
    },
    {
      name: 'type',
      type: 'portal',
      is_visible: true,
      get short_title() {
        return $t('Inventories.inventoryType')
      },
      get title() {
        return $t('Inventories.inventoryType')
      }
    },
    {
      name: 'inventoryClosingMethod',
      type: 'portal',
      is_visible: true,
      get short_title() {
        return $t('Inventories.closingMethod')
      },
      get title() {
        return $t('Inventories.closingMethod')
      }
    }
  ]
}
