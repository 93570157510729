<template lang="pug">
  Popup
    template(slot="title") {{ $t('Inventory.addBatchDetail') }}
    
    template(slot="content")
      form.Form(
        id="form-inventory-batch-create"
        @submit.prevent="onSubmitForm"
      )

        .Form-item.required
          label.Form-item-label {{ $t('Inventory.Form_StockItemName') }}
          .control
            custom-search(
              name="stockItem"
              component-name="form-inventory-detail-batch-stock-item"
              v-model="stockItem"
              :data-vv-as="$t('Inventory.Form_StockItemName')"
              v-validate="'required'"
              ref="focusableInput"
              :inputClass="{ 'is-danger': veeErrors.has('stockItem') }"
              :on-search="getOptions"
              :options="selectOptions"
              label="name"
              :placeholder="$t('Inventory.Form_StockItemName_Placeholder')"
              :directSelectOption="directSelectOption"
            )

            showValidateError(
              v-show="veeErrors.has('stockItem')"
              :errorName="veeErrors.first('stockItem')"
            )
        .Form-item.required.m-top-15
          label.Form-item-label {{ $t('Despatches.detail_Popup_FormField_Quantity') }}
          .control
            customNumberInput(
              id="input-inventory-detail-batch-form-quantity"
              name="quantity"
              :data-vv-as="$t('Despatches.detail_Popup_FormField_Quantity')"
              v-model="form.quantity"
              v-validate="{required: true, decimal:$getConst('DECIMAL_FOR_QUANTITY'), greater_than:0, max: $getConst('MAX_DIGITS_FOR_QUANTITY')}"
              :class="{ 'is-danger': veeErrors.has('quantity') }"
            )
          
            showValidateError(
              v-show="veeErrors.has('quantity')"
              :errorName="veeErrors.first('quantity')"
            )
        .Form-item
          label.Form-item-label {{ $t('Despatches.shelfStorageLocation') }}
          .control.form-item-select

            customSelect(
              v-model="form.shelfStorageLocationListHeaderId"
              selectedEmitValue="id"
              :defaultTitle="$t('Despatches.selectShelfStorageLocation')"
              :optionData="ShelfListFromStore"
              optionTitle="name"
              componentName="inventory-shelf-storage-location-select"
            )
        .Form-item.m-top-15
          label.Form-item-label {{ $t('Despatches.productionDate') }}
          .control.form-item-select
            CustomDatepicker(
              id="inventory-detail-batch-form-production-date"
              name="productionDate",
              :data-vv-as="$t('Despatches.productionDate') "
              :useFilterReset="true"
              v-model="form.productionDate",
              :class="{ 'is-danger': veeErrors.has(`productionDate`) }"
              pickerType="manuel",
              inputIconRight="icon-datepicker-big"
              inputIcon=""
              @resetDateFilter="resetDate('productionDate')"
            )
            showValidateError(
              v-show="veeErrors.has('productionDate')"
              :errorName="veeErrors.first('productionDate')"
            )
        
        .Form-item.m-top-15
          label.Form-item-label {{ $t('Despatches.shelfLife') }}
          .control
            customNumberInput(
              id="input-inventory-detail-batch-form-shelfLife"
              name="shelfLife"
              :data-vv-as="$t('Despatches.shelfLife')"
              v-model="form.shelfLife"
              :class="{ 'is-danger': veeErrors.has('shelfLife') }"
            )
          
            showValidateError(
              v-show="veeErrors.has('shelfLife')"
              :errorName="veeErrors.first('shelfLife')"
            )
        
        .Form-item.m-top-15
          label.Form-item-label {{ $t('Despatches.expirationDate') }}
          .control.form-item-select
            CustomDatepicker(
              id="inventory-detail-batch-form-expiration-date"
              name="expirationDate",
              :useFilterReset="true"
              :data-vv-as="$t('Despatches.expirationDate') ",
              v-model="form.expirationDate",
              :class="{ 'is-danger': veeErrors.has(`expirationDate`) }"
              pickerType="manuel",
              inputIconRight="icon-datepicker-big"
              inputIcon=""
              @resetDateFilter="resetDate('expirationDate')"
            )
            showValidateError(
              v-show="veeErrors.has('expirationDate')"
              :errorName="veeErrors.first('expirationDate')"
            )

        
        .Form-group.m-top-15
          .Form-group-item
            label.Form-item-label {{ $t('Despatches.degree') }}
            .control
              customNumberInput(
                id="input-inventory-detail-batch-form-degree"
                name="degree"
                v-model="form.degree"
              )
          .Form-group-item
            label.Form-item-label {{ $t('Despatches.shipmentTime') }}
            .control
              input.time-input(type="time", v-model="form.shipmentTime")

          .Form-group-item
            label.Form-item-label {{ $t('Despatches.warehouseTime') }}
            .control
              input.time-input(type="time", v-model="form.warehouseTime")

    template(slot="footer")
      div.add-other-items-checkbox-container
        CustomCheckbox(
        id="checkbox-inventory-batch-save-and-new"
        :label="$t('Global.formSaveAndNewText', { form: $t('Inventory.batch_Form_Name') })"
        v-model="saveAndNew")
      
      Button(
        primary
        id="btn-inventory-batch-form-submit"
        variant="full"
        size="medium"
        type="submit",
        form="form-inventory-batch-create"
        :disabled="isLoading"
        :isLoading="isLoading"
      )
        span(
          v-show="!isLoading"
        )
          template {{ $t('Inventory.form_FinishBatchCreateButton') }}


                
          
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import customSelect from '@/view/global/custom-select'
import { vueWaitLoader } from '@/utils/baseOperations'

export default {
  name: 'InventoryBatchFrom',

  data () {
    return {
      form: {
        quantity: null,
        productionDate: null,
        shelfLife: null,
        expirationDate: null,
        degree: null,
        shipmentTime: null,
        warehouseTime: null,
        shelfStorageLocationListHeaderId: null
      },
      selectOptions: [],
      saveAndNew: true,
      stockItem: {}
    }
  },

  components: {
    customSelect
  },

  async mounted () {
    this.getShelfList()
  },

  computed: {

    ...mapGetters('ListsShelfStorageLocation', ['ShelfListFromStore']),
    
    directSelectOption () {
      return this.selectOptions.length === 1 &&
        this.selectOptions[0].matchType === 2
        ? this.selectOptions[0]
        : {}
    },

    isLoading () {
      return this.$wait.is('submitExchangeForm')
    }
  },

  methods: {
    ...mapActions('Stock', ['searchStockItem', 'getStockItem']),
    ...mapActions('Notify', ['notifyShow']),
    ...mapActions('ListsShelfStorageLocation', ['getAllShelvesFromStore']),
    ...mapActions('Inventories', ['createInventoryBatch']),

    async getOptions (search) {
      await this.searchStockItem({Text: search, types: [1, 2, 3]})
        .then(res => {
          this.selectOptions = res.data.items
        })
    },

    async getShelfList () {
      const payload = {
        storeId: this.$route.params.storeId
      }
      await this.getAllShelvesFromStore(payload)
    },

    resetDate (date) {
      this.form[date] = null
    },

    resetForm () {
      this.form = {
        quantity: null,
        productionDate: null,
        shelfLife: null,
        expirationDate: null,
        degree: null,
        shipmentTime: null,
        warehouseTime: null,
        shelfStorageLocationListHeaderId: null
      }
    },

    close () {
      this.$router.push({
        name: this.$route.matched[0].name
      })
    },

    onSubmitForm: vueWaitLoader(async function () {
      await this.$validator.validateAll().then(async isValid => {
        if (!isValid) return
        const payload = {
          ...this.form,
          stockItemId: this.stockItem.id,
          inventoryHeaderId: this.$route.params.id
        }
        await this.createInventoryBatch(payload).then(res => {
          if (res) {
            const message = this.$t('Inventory.notification_BatchCreated')
            this.notifyShow({ message })
            if (this.saveAndNew) {
              this.resetForm()
              this.$nextTick(() => {
                this.$validator.reset()
              })
            } else {
              this.close()
            }
            this.$emit('getList')
          }
        })
      })
    }, 'submitExchangeForm')
  }
}
</script>

<style lang="scss" scoped>
  .Form-group {
    display: flex;
    margin-top: 15px;
    align-items: flex-start;
    justify-content: space-between;
  }
  .time-input {
    min-height: 50px;
    color: #323232;
    border: solid 1px #eaeaeb;
  }
  :deep(.select-dropdown-custom-selected) {
    width: unset !important;
  }
  :deep(.icon-datepicker-big) {
    width: 21px !important;
    height: 21px !important;
  }
</style>