var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "Popup",
    [
      _c("template", { slot: "title" }, [
        _vm._v(_vm._s(_vm.$t("Inventory.addBatchDetail"))),
      ]),
      _c("template", { slot: "content" }, [
        _c(
          "form",
          {
            staticClass: "Form",
            attrs: { id: "form-inventory-batch-create" },
            on: {
              submit: function ($event) {
                $event.preventDefault()
                return _vm.onSubmitForm.apply(null, arguments)
              },
            },
          },
          [
            _c("div", { staticClass: "Form-item required" }, [
              _c("label", { staticClass: "Form-item-label" }, [
                _vm._v(_vm._s(_vm.$t("Inventory.Form_StockItemName"))),
              ]),
              _c(
                "div",
                { staticClass: "control" },
                [
                  _c("custom-search", {
                    directives: [
                      {
                        name: "validate",
                        rawName: "v-validate",
                        value: "required",
                        expression: "'required'",
                      },
                    ],
                    ref: "focusableInput",
                    attrs: {
                      name: "stockItem",
                      "component-name":
                        "form-inventory-detail-batch-stock-item",
                      "data-vv-as": _vm.$t("Inventory.Form_StockItemName"),
                      inputClass: {
                        "is-danger": _vm.veeErrors.has("stockItem"),
                      },
                      "on-search": _vm.getOptions,
                      options: _vm.selectOptions,
                      label: "name",
                      placeholder: _vm.$t(
                        "Inventory.Form_StockItemName_Placeholder"
                      ),
                      directSelectOption: _vm.directSelectOption,
                    },
                    model: {
                      value: _vm.stockItem,
                      callback: function ($$v) {
                        _vm.stockItem = $$v
                      },
                      expression: "stockItem",
                    },
                  }),
                  _c("showValidateError", {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.veeErrors.has("stockItem"),
                        expression: "veeErrors.has('stockItem')",
                      },
                    ],
                    attrs: { errorName: _vm.veeErrors.first("stockItem") },
                  }),
                ],
                1
              ),
            ]),
            _c("div", { staticClass: "Form-item required m-top-15" }, [
              _c("label", { staticClass: "Form-item-label" }, [
                _vm._v(
                  _vm._s(_vm.$t("Despatches.detail_Popup_FormField_Quantity"))
                ),
              ]),
              _c(
                "div",
                { staticClass: "control" },
                [
                  _c("customNumberInput", {
                    directives: [
                      {
                        name: "validate",
                        rawName: "v-validate",
                        value: {
                          required: true,
                          decimal: _vm.$getConst("DECIMAL_FOR_QUANTITY"),
                          greater_than: 0,
                          max: _vm.$getConst("MAX_DIGITS_FOR_QUANTITY"),
                        },
                        expression:
                          "{required: true, decimal:$getConst('DECIMAL_FOR_QUANTITY'), greater_than:0, max: $getConst('MAX_DIGITS_FOR_QUANTITY')}",
                      },
                    ],
                    class: { "is-danger": _vm.veeErrors.has("quantity") },
                    attrs: {
                      id: "input-inventory-detail-batch-form-quantity",
                      name: "quantity",
                      "data-vv-as": _vm.$t(
                        "Despatches.detail_Popup_FormField_Quantity"
                      ),
                    },
                    model: {
                      value: _vm.form.quantity,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "quantity", $$v)
                      },
                      expression: "form.quantity",
                    },
                  }),
                  _c("showValidateError", {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.veeErrors.has("quantity"),
                        expression: "veeErrors.has('quantity')",
                      },
                    ],
                    attrs: { errorName: _vm.veeErrors.first("quantity") },
                  }),
                ],
                1
              ),
            ]),
            _c("div", { staticClass: "Form-item" }, [
              _c("label", { staticClass: "Form-item-label" }, [
                _vm._v(_vm._s(_vm.$t("Despatches.shelfStorageLocation"))),
              ]),
              _c(
                "div",
                { staticClass: "control form-item-select" },
                [
                  _c("customSelect", {
                    attrs: {
                      selectedEmitValue: "id",
                      defaultTitle: _vm.$t(
                        "Despatches.selectShelfStorageLocation"
                      ),
                      optionData: _vm.ShelfListFromStore,
                      optionTitle: "name",
                      componentName: "inventory-shelf-storage-location-select",
                    },
                    model: {
                      value: _vm.form.shelfStorageLocationListHeaderId,
                      callback: function ($$v) {
                        _vm.$set(
                          _vm.form,
                          "shelfStorageLocationListHeaderId",
                          $$v
                        )
                      },
                      expression: "form.shelfStorageLocationListHeaderId",
                    },
                  }),
                ],
                1
              ),
            ]),
            _c("div", { staticClass: "Form-item m-top-15" }, [
              _c("label", { staticClass: "Form-item-label" }, [
                _vm._v(_vm._s(_vm.$t("Despatches.productionDate"))),
              ]),
              _c(
                "div",
                { staticClass: "control form-item-select" },
                [
                  _c("CustomDatepicker", {
                    class: { "is-danger": _vm.veeErrors.has(`productionDate`) },
                    attrs: {
                      id: "inventory-detail-batch-form-production-date",
                      name: "productionDate",
                      "data-vv-as": _vm.$t("Despatches.productionDate"),
                      useFilterReset: true,
                      pickerType: "manuel",
                      inputIconRight: "icon-datepicker-big",
                      inputIcon: "",
                    },
                    on: {
                      resetDateFilter: function ($event) {
                        return _vm.resetDate("productionDate")
                      },
                    },
                    model: {
                      value: _vm.form.productionDate,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "productionDate", $$v)
                      },
                      expression: "form.productionDate",
                    },
                  }),
                  _c("showValidateError", {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.veeErrors.has("productionDate"),
                        expression: "veeErrors.has('productionDate')",
                      },
                    ],
                    attrs: { errorName: _vm.veeErrors.first("productionDate") },
                  }),
                ],
                1
              ),
            ]),
            _c("div", { staticClass: "Form-item m-top-15" }, [
              _c("label", { staticClass: "Form-item-label" }, [
                _vm._v(_vm._s(_vm.$t("Despatches.shelfLife"))),
              ]),
              _c(
                "div",
                { staticClass: "control" },
                [
                  _c("customNumberInput", {
                    class: { "is-danger": _vm.veeErrors.has("shelfLife") },
                    attrs: {
                      id: "input-inventory-detail-batch-form-shelfLife",
                      name: "shelfLife",
                      "data-vv-as": _vm.$t("Despatches.shelfLife"),
                    },
                    model: {
                      value: _vm.form.shelfLife,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "shelfLife", $$v)
                      },
                      expression: "form.shelfLife",
                    },
                  }),
                  _c("showValidateError", {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.veeErrors.has("shelfLife"),
                        expression: "veeErrors.has('shelfLife')",
                      },
                    ],
                    attrs: { errorName: _vm.veeErrors.first("shelfLife") },
                  }),
                ],
                1
              ),
            ]),
            _c("div", { staticClass: "Form-item m-top-15" }, [
              _c("label", { staticClass: "Form-item-label" }, [
                _vm._v(_vm._s(_vm.$t("Despatches.expirationDate"))),
              ]),
              _c(
                "div",
                { staticClass: "control form-item-select" },
                [
                  _c("CustomDatepicker", {
                    class: { "is-danger": _vm.veeErrors.has(`expirationDate`) },
                    attrs: {
                      id: "inventory-detail-batch-form-expiration-date",
                      name: "expirationDate",
                      useFilterReset: true,
                      "data-vv-as": _vm.$t("Despatches.expirationDate"),
                      pickerType: "manuel",
                      inputIconRight: "icon-datepicker-big",
                      inputIcon: "",
                    },
                    on: {
                      resetDateFilter: function ($event) {
                        return _vm.resetDate("expirationDate")
                      },
                    },
                    model: {
                      value: _vm.form.expirationDate,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "expirationDate", $$v)
                      },
                      expression: "form.expirationDate",
                    },
                  }),
                  _c("showValidateError", {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.veeErrors.has("expirationDate"),
                        expression: "veeErrors.has('expirationDate')",
                      },
                    ],
                    attrs: { errorName: _vm.veeErrors.first("expirationDate") },
                  }),
                ],
                1
              ),
            ]),
            _c("div", { staticClass: "Form-group m-top-15" }, [
              _c("div", { staticClass: "Form-group-item" }, [
                _c("label", { staticClass: "Form-item-label" }, [
                  _vm._v(_vm._s(_vm.$t("Despatches.degree"))),
                ]),
                _c(
                  "div",
                  { staticClass: "control" },
                  [
                    _c("customNumberInput", {
                      attrs: {
                        id: "input-inventory-detail-batch-form-degree",
                        name: "degree",
                      },
                      model: {
                        value: _vm.form.degree,
                        callback: function ($$v) {
                          _vm.$set(_vm.form, "degree", $$v)
                        },
                        expression: "form.degree",
                      },
                    }),
                  ],
                  1
                ),
              ]),
              _c("div", { staticClass: "Form-group-item" }, [
                _c("label", { staticClass: "Form-item-label" }, [
                  _vm._v(_vm._s(_vm.$t("Despatches.shipmentTime"))),
                ]),
                _c("div", { staticClass: "control" }, [
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.form.shipmentTime,
                        expression: "form.shipmentTime",
                      },
                    ],
                    staticClass: "time-input",
                    attrs: { type: "time" },
                    domProps: { value: _vm.form.shipmentTime },
                    on: {
                      input: function ($event) {
                        if ($event.target.composing) return
                        _vm.$set(_vm.form, "shipmentTime", $event.target.value)
                      },
                    },
                  }),
                ]),
              ]),
              _c("div", { staticClass: "Form-group-item" }, [
                _c("label", { staticClass: "Form-item-label" }, [
                  _vm._v(_vm._s(_vm.$t("Despatches.warehouseTime"))),
                ]),
                _c("div", { staticClass: "control" }, [
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.form.warehouseTime,
                        expression: "form.warehouseTime",
                      },
                    ],
                    staticClass: "time-input",
                    attrs: { type: "time" },
                    domProps: { value: _vm.form.warehouseTime },
                    on: {
                      input: function ($event) {
                        if ($event.target.composing) return
                        _vm.$set(_vm.form, "warehouseTime", $event.target.value)
                      },
                    },
                  }),
                ]),
              ]),
            ]),
          ]
        ),
      ]),
      _c(
        "template",
        { slot: "footer" },
        [
          _c(
            "div",
            { staticClass: "add-other-items-checkbox-container" },
            [
              _c("CustomCheckbox", {
                attrs: {
                  id: "checkbox-inventory-batch-save-and-new",
                  label: _vm.$t("Global.formSaveAndNewText", {
                    form: _vm.$t("Inventory.batch_Form_Name"),
                  }),
                },
                model: {
                  value: _vm.saveAndNew,
                  callback: function ($$v) {
                    _vm.saveAndNew = $$v
                  },
                  expression: "saveAndNew",
                },
              }),
            ],
            1
          ),
          _c(
            "Button",
            {
              attrs: {
                primary: "",
                id: "btn-inventory-batch-form-submit",
                variant: "full",
                size: "medium",
                type: "submit",
                form: "form-inventory-batch-create",
                disabled: _vm.isLoading,
                isLoading: _vm.isLoading,
              },
            },
            [
              _c(
                "span",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: !_vm.isLoading,
                      expression: "!isLoading",
                    },
                  ],
                },
                [
                  [
                    _vm._v(
                      _vm._s(_vm.$t("Inventory.form_FinishBatchCreateButton"))
                    ),
                  ],
                ],
                2
              ),
            ]
          ),
        ],
        1
      ),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }