export default function getFields($t) {
  return [
    {
      name: 'recipeName',
      is_visible: true,
      get short_title() {
        return $t('Inventory.Table_Inventory_Count_Recipe_Name')
      },
      get title() {
        return $t('Inventory.Table_Inventory_Count_Recipe_Name')
      }
    },
    {
      name: 'unitNameWithQuantity',
      is_visible: true,
      get short_title() {
        return $t('Inventory.Table_Inventory_Count_Recipe_unitNameWithQuantity')
      },
      get title() {
        return $t('Inventory.Table_Inventory_Count_Recipe_unitNameWithQuantity')
      }
    },
    {
      name: 'quantity',
      type: 'portal',
      is_visible: true,
      get short_title() {
        return $t('Inventory.Table_Inventory_Count_Recipe_quantity')
      },
      get title() {
        return $t('Inventory.Table_Inventory_Count_Recipe_quantity')
      }
    },
    {
      name: 'totalQuantity',
      is_visible: true,
      get short_title() {
        return $t('Inventory.Table_Inventory_Count_Recipe_totalQuantity')
      },
      get title() {
        return $t('Inventory.Table_Inventory_Count_Recipe_totalQuantity')
      }
    }
  ]
}
