export default function getFields($t) {
  return [
    {
      name: 'itemGroupName',
      fixedColumnHeader: true,
      type: 'portal',
      is_visible: true,
      is_sortable: true,
      sortingObject: {
        columnName: 'itemGroupName',
        sortType: null
      },
      get short_title() {
        return $t('Inventory.detailList_TableColumn_ItemGroupName')
      },
      get title() {
        return $t('Inventory.detailList_TableColumn_ItemGroupName')
      }
    },
    {
      name: 'stockItemName',
      fixedColumnHeader: true,
      type: 'portal',
      is_visible: true,
      is_sortable: true,
      sortingObject: {
        columnName: 'stockItemName',
        sortType: null
      },
      get short_title() {
        return $t('Inventory.detailList_TableColumn_StockItemName')
      },
      get title() {
        return $t('Inventory.detailList_TableColumn_StockItemName')
      }
    },
    {
      name: 'stockItemUnitCode',
      is_visible: true,
      type: 'mono',
      get short_title() {
        return $t('Inventory.detailList_TableColumn_StockItemUnitCode')
      },
      get title() {
        return $t('Inventory.detailList_TableColumn_StockItemUnitCode')
      }
    },
    {
      name: 'beginQuantity',
      is_visible: true,
      type: 'mono',
      get short_title() {
        return $t('Inventory.detailList_TableColumn_BeginQuantity')
      },
      get title() {
        return $t('Inventory.detailList_TableColumn_BeginQuantity')
      }
    },
    {
      name: 'beginAmount',
      is_visible: true,
      type: 'mono',
      get short_title() {
        return $t('Inventory.detailList_TableColumn_BeginAmount')
      },
      get title() {
        return $t('Inventory.detailList_TableColumn_BeginAmount')
      }
    },
    {
      name: 'purchaseQuantity',
      type: 'mono',
      is_visible: true,
      get short_title() {
        return $t('Inventory.detailList_TableColumn_PurchaseQuantity')
      },
      get title() {
        return $t('Inventory.detailList_TableColumn_PurchaseQuantity')
      }
    },
    {
      name: 'purchaseAmount',
      type: 'mono',
      is_visible: true,
      get short_title() {
        return $t('Inventory.detailList_TableColumn_PurchaseAmount')
      },
      get title() {
        return $t('Inventory.detailList_TableColumn_PurchaseAmount')
      }
    },
    {
      name: 'returnQuantity',
      type: 'mono',
      is_visible: true,
      get short_title() {
        return $t('Inventory.detailList_TableColumn_ReturnQuantity')
      },
      get title() {
        return $t('Inventory.detailList_TableColumn_ReturnQuantity')
      }
    },
    {
      name: 'returnAmount',
      type: 'mono',
      is_visible: true,
      get short_title() {
        return $t('Inventory.detailList_TableColumn_ReturnAmount')
      },
      get title() {
        return $t('Inventory.detailList_TableColumn_ReturnAmount')
      }
    },
    {
      name: 'transferredQuantityIncoming',
      type: 'mono',
      is_visible: true,
      get short_title() {
        return $t('Inventory.detailList_TableColumn_TransferredQuantityIncoming')
      },
      get title() {
        return $t('Inventory.detailList_TableColumn_TransferredQuantityIncoming')
      }
    },
    {
      name: 'transferredAmountIncoming',
      type: 'mono',
      is_visible: true,
      get short_title() {
        return $t('Inventory.detailList_TableColumn_TransferredAmountIncoming')
      },
      get title() {
        return $t('Inventory.detailList_TableColumn_TransferredAmountIncoming')
      }
    },
    {
      name: 'transferredQuantity',
      type: 'mono',
      is_visible: true,
      get short_title() {
        return $t('Inventory.detailList_TableColumn_TransferredQuantitiy')
      },
      get title() {
        return $t('Inventory.detailList_TableColumn_TransferredQuantitiy')
      }
    },
    {
      name: 'transferredAmount',
      type: 'mono',
      is_visible: true,
      get short_title() {
        return $t('Inventory.detailList_TableColumn_TransferredAmount')
      },
      get title() {
        return $t('Inventory.detailList_TableColumn_TransferredAmount')
      }
    },
    {
      name: 'productionQuantity',
      type: 'mono',
      is_visible: true,
      get short_title() {
        return $t('Inventory.detailList_TableColumn_ProductionQuantity')
      },
      get title() {
        return $t('Inventory.detailList_TableColumn_ProductionQuantity')
      }
    },
    {
      name: 'productionAmount',
      type: 'mono',
      is_visible: true,
      get short_title() {
        return $t('Inventory.detailList_TableColumn_ProductionAmount')
      },
      get title() {
        return $t('Inventory.detailList_TableColumn_ProductionAmount')
      }
    },
    {
      name: 'consumedForProductionQuantity',
      type: 'mono',
      is_visible: true,
      get short_title() {
        return $t('Inventory.detailList_TableColumn_ConsumedForProductionQuantity')
      },
      get title() {
        return $t('Inventory.detailList_TableColumn_ConsumedForProductionQuantity')
      }
    },
    {
      name: 'consumedForProductionAmount',
      type: 'mono',
      is_visible: true,
      get short_title() {
        return $t('Inventory.detailList_TableColumn_ConsumedForProductionAmount')
      },
      get title() {
        return $t('Inventory.detailList_TableColumn_ConsumedForProductionAmount')
      }
    },
    {
      name: 'yieldQuantity',
      type: 'mono',
      is_visible: true,
      get short_title() {
        return $t('Inventory.detailList_TableColumn_yieldQuantity')
      },
      get title() {
        return $t('Inventory.detailList_TableColumn_yieldQuantity')
      }
    },
    {
      name: 'yieldAmount',
      type: 'mono',
      is_visible: true,
      get short_title() {
        return $t('Inventory.detailList_TableColumn_yieldAmount')
      },
      get title() {
        return $t('Inventory.detailList_TableColumn_yieldAmount')
      }
    },
    {
      name: 'consumedForYieldQuantity',
      type: 'mono',
      is_visible: true,
      get short_title() {
        return $t('Inventory.detailList_TableColumn_consumedForYieldQuantity')
      },
      get title() {
        return $t('Inventory.detailList_TableColumn_consumedForYieldQuantity')
      }
    },
    {
      name: 'consumedForYieldAmount',
      type: 'mono',
      is_visible: true,
      get short_title() {
        return $t('Inventory.detailList_TableColumn_consumedForYieldAmount')
      },
      get title() {
        return $t('Inventory.detailList_TableColumn_consumedForYieldAmount')
      }
    },
    {
      name: 'potentialConsumptionQuantity',
      type: 'mono',
      is_visible: true,
      get short_title() {
        return $t('Inventory.detailList_TableColumn_PotentialConsumptionQuantity')
      },
      get title() {
        return $t('Inventory.detailList_TableColumn_PotentialConsumptionQuantity')
      }
    },
    {
      name: 'potentialConsumptionAmount',
      type: 'mono',
      is_visible: true,
      get short_title() {
        return $t('Inventory.detailList_TableColumn_PotentialConsumptionAmount')
      },
      get title() {
        return $t('Inventory.detailList_TableColumn_PotentialConsumptionAmount')
      }
    },
    {
      name: 'wasteQuantity',
      type: 'mono',
      is_visible: true,
      get short_title() {
        return $t('Inventory.detailList_TableColumn_WasteQuantity')
      },
      get title() {
        return $t('Inventory.detailList_TableColumn_WasteQuantity')
      }
    },
    {
      name: 'wasteCostAmount',
      type: 'mono',
      is_visible: true,
      get short_title() {
        return $t('Inventory.detailList_TableColumn_WasteAmount')
      },
      get title() {
        return $t('Inventory.detailList_TableColumn_WasteAmount')
      }
    },
    {
      name: 'actualQuantity',
      type: 'mono',
      is_visible: true,
      get short_title() {
        return $t('Inventory.detailList_TableColumn_ActualQuantity')
      },
      get title() {
        return $t('Inventory.detailList_TableColumn_ActualQuantity')
      }
    },
    {
      name: 'actualAmount',
      type: 'mono',
      is_visible: true,
      get short_title() {
        return $t('Inventory.detailList_TableColumn_ActualAmount')
      },
      get title() {
        return $t('Inventory.detailList_TableColumn_ActualAmount')
      }
    },
    {
      name: 'expectedQuantity',
      type: 'mono',
      is_visible: true,
      get short_title() {
        return $t('Inventory.detailList_TableColumn_ExpectedQuantity')
      },
      get title() {
        return $t('Inventory.detailList_TableColumn_ExpectedQuantity')
      }
    },
    {
      name: 'expectedAmount',
      type: 'mono',
      is_visible: true,
      get short_title() {
        return $t('Inventory.detailList_TableColumn_ExpectedAmount')
      },
      get title() {
        return $t('Inventory.detailList_TableColumn_ExpectedAmount')
      }
    },
    {
      name: 'consumptionQuantity',
      type: 'mono',
      is_visible: true,
      get short_title() {
        return $t('Inventory.detailList_TableColumn_ConsumptionQuantity')
      },
      get title() {
        return $t('Inventory.detailList_TableColumn_ConsumptionQuantity')
      }
    },
    {
      name: 'consumptionAmount',
      type: 'mono',
      is_visible: true,
      get short_title() {
        return $t('Inventory.detailList_TableColumn_ConsumptionAmount')
      },
      get title() {
        return $t('Inventory.detailList_TableColumn_ConsumptionAmount')
      }
    },
    {
      name: 'differenceQuantity',
      type: 'mono',
      is_visible: true,
      get short_title() {
        return $t('Inventory.detailList_TableColumn_DifferenceQuantity')
      },
      get title() {
        return $t('Inventory.detailList_TableColumn_DifferenceQuantity')
      }
    },
    {
      name: 'differenceAmount',
      type: 'mono',
      is_visible: true,
      get short_title() {
        return $t('Inventory.detailList_TableColumn_DifferenceAmount')
      },
      get title() {
        return $t('Inventory.detailList_TableColumn_DifferenceAmount')
      }
    },
    {
      name: 'detail',
      type: 'portal',
      is_visible: true,
      get short_title() {
        return $t('Inventory.detailList_TableColumn_DifferenceQuantityRatio')
      },
      get title() {
        return $t('Inventory.detailList_TableColumn_DifferenceQuantityRatio')
      }
    },
  ]
}
