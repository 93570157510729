import BaseClass from '@/utils/baseclass'

// INVENTORIES

export class Inventories extends BaseClass {
  addItem(item) {
    let newItem = new InventoryHeader(item)
    this.list.push(newItem)
  }
}

class InventoryHeader {
  constructor(item) {
    this.id = item.id
    this.inventoryDate = item.inventoryDate
    this.storeId = item.storeId
    this.storeName = item.storeName
    this.status = item.status
    this.isLastClosed = item.isLastClosed
    this.helperBox = false
    this.type = item.type
    this.storeType = item.storeType
    this.inventoryClosingMethod = item.inventoryClosingMethod
    this.rowTextClass = item.status === 2 ? 'TableViewPassiveItemsNoBorder' : ''
  }
}

// INVENTORY

export class Inventory extends BaseClass {
  constructor(item) {
    super()
    this.id = item.id
    this.inventoryNumber = item.inventoryNumber
    this.inventoryDate = item.inventoryDate
    this.status = item.status
    this.inventoryClosingMethod = item.inventoryClosingMethod
  }

  addItem(item) {
    let newItem
    // Open inventory
    if (this.status === 1) {
      newItem = new InventoryDetail(item)
    // Closed inventory
    } else if (this.status === 2) {
      newItem = new InventoryDetailClosed(item)
    }
    this.list.push(newItem)
  }
}

class InventoryDetail {
  constructor(item, inventoryClosingMethod) {
    this.id = item.id
    this.itemGroupName = item.itemGroupName
    this.baseUnitCode = item.baseUnitCode
    this.expectedQuantity = item.expectedQuantity
    this.stockItemId = item.stockItemId
    this.stockItemName = item.stockItemName
    this.barcodes = item.barcodes
    this.quantityByList = item.quantityByList
    this.inventoryClosingMethod = inventoryClosingMethod
    this.unitCountList = item.unitCountList.map(item => new InventoryDetailUnitCountItem(item))
  }
}

class InventoryDetailUnitCountItem {
  constructor(item) {
    this.baseQuantity = item.baseQuantity
    this.inventoryCountId = item.inventoryCountId
    this.unitCode = item.unitCode
    this.unitId = item.unitId
    this.unitName = item.unitName
    this.unitQuantity = item.unitQuantity
  }
}

class InventoryDetailClosed {
  constructor(item, inventoryClosingMethod) {
    this.itemGroupName = item.itemGroupName
    this.actualAmount = item.actualAmount
    this.actualQuantity = item.actualQuantity
    this.beginAmount = item.beginAmount
    this.beginQuantity = item.beginQuantity
    this.consumptionAmount = item.consumptionAmount
    this.consumptionQuantity = item.consumptionQuantity
    this.differenceAmount = item.differenceAmount
    this.differenceQuantity = item.differenceQuantity
    this.expectedAmount = item.expectedAmount
    this.expectedQuantity = item.expectedQuantity
    this.id = item.id
    this.isAssignedBySystem = item.isAssignedBySystem
    this.potentialConsumptionAmount = item.potentialConsumptionAmount
    this.productionQuantity = item.productionQuantity
    this.productionAmount = item.productionAmount
    this.consumedForProductionQuantity = item.consumedForProductionQuantity
    this.consumedForProductionAmount = item.consumedForProductionAmount
    this.potentialConsumptionQuantity = item.potentialConsumptionQuantity
    this.purchaseAmount = item.purchaseAmount
    this.purchaseQuantity = item.purchaseQuantity
    this.stockItemId = item.stockItemId
    this.stockItemName = item.stockItemName
    this.stockItemUnitCode = item.stockItemUnitCode
    this.transferredAmount = item.transferredAmount
    this.transferredAmountIncoming = item.transferredAmountIncoming
    this.transferredQuantity = item.transferredQuantity
    this.transferredQuantityIncoming = item.transferredQuantityIncoming
    this.returnAmount = item.returnAmount
    this.returnQuantity = item.returnQuantity
    this.wasteQuantity = item.wasteQuantity
    this.wasteCostAmount = item.wasteCostAmount
    this.yieldAmount = item.yieldAmount
    this.yieldQuantity = item.yieldQuantity
    this.consumedForYieldQuantity = item.consumedForYieldQuantity
    this.consumedForYieldAmount = item.consumedForYieldAmount
    this.inventoryClosingMethod = item.inventoryClosingMethod
  }
}
