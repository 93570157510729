import BaseClass from '@/utils/baseclass'

export default class InventoryShelfList extends BaseClass {

  
  addItem(item) {
    let newItem = new ShelfList(item)
    this.list.push(newItem)
  }
  updateItem(item) {
    let findItem = this.findItem(item.id)
    if (findItem) {
      findItem.orderNumber = item.orderNumber
      findItem.inventoryItemList = item.inventoryItemList
      findItem.shelfStorageLocationListHeaderId = item.shelfStorageLocationListHeaderId
      findItem.shelfStorageLocationListHeaderName = item.shelfStorageLocationListHeaderName
      findItem.detailList = []
      findItem.showDetail = item.showDetail
    }
  }
}
export class ShelfList {
  constructor(item) {
    this.orderNumber = item.orderNumber
    this.inventoryItemList = item.inventoryItemList
    this.shelfStorageLocationListHeaderId = item.shelfStorageLocationListHeaderId
    this.shelfStorageLocationListHeaderName = item.shelfStorageLocationListHeaderName
    this.detailList = []
    this.showDetail = false
  }

  addDetails(list) {
    this.detailList = []
    list.forEach(detail => {
      this.detailList.push(new ShelfListDetail(detail, this.shelfStorageLocationListHeaderId))
    })
  }
  findItem(Id) {
    return this.detailList.find(item => item.shelfStorageLocationListHeaderId === Id)
  }
}

export class ShelfListDetail {
  constructor(detail, shelfStorageLocationListHeaderId) {
    this.baseUnitCode = detail.baseUnitCode
    this.expectedQuantity = detail.expectedQuantity
    this.quantityByList = detail.quantityByList
    this.stockItemId = detail.stockItemId
    this.stockItemName = detail.stockItemName
    this.shelfStorageLocationListHeaderId = shelfStorageLocationListHeaderId
    this.totalCount = detail.totalCount
    this.unitCountList = detail.unitCountList.map(detail => new InventoryDetailUnitCountItem(detail, shelfStorageLocationListHeaderId, this.stockItemId))
  }
}
class InventoryDetailUnitCountItem {
  constructor(item, shelfStorageLocationListHeaderId, stockItemId) {
    this.baseQuantity = item.baseQuantity
    this.inventoryCountId = item.inventoryCountId
    this.unitCode = item.unitCode
    this.unitId = item.unitId
    this.unitName = item.unitName
    this.unitQuantity = item.unitQuantity
    this.shelfStorageLocationListHeaderId = shelfStorageLocationListHeaderId
    this.stockItemId = stockItemId
    this.totalUnitQuantity = item.baseQuantity * item.unitQuantity
  }
}