var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _vm.InventoryInfo.inventoryClosingMethod !== 2 || _vm.showFinalAlert
        ? _c("Alert", {
            attrs: {
              title: _vm.$t("Inventory.confirm_CloseInventory"),
              description: _vm.$t("Inventory.confirm_CloseInventory_Message"),
              okButton: _vm.$t("Inventory.confirm_CloseInventory_Close"),
              icon: "icon-global-notify",
              loading: "itemCloseInventory",
            },
            on: {
              delegateOnAlertOk: _vm.itemClose,
              delegateOnAlertCancel: _vm.close,
            },
          })
        : _vm._e(),
      _vm.InventoryInfo.inventoryClosingMethod === 2 && _vm.showFirstAlert
        ? _c("Alert", {
            attrs: {
              title: _vm.$t("Inventory.confirm_uncountedDelete"),
              description: _vm.$t("Inventory.confirm_uncountedMessage"),
              okButton: _vm.$t("Inventory.confirm_CloseInventory_Close"),
              icon: "icon-global-notify",
              loading: "itemCloseInventory",
            },
            on: {
              delegateOnAlertOk: _vm.confirmFirstAlert,
              delegateOnAlertCancel: _vm.close,
            },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }