var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.massecIsActive
    ? _c(
        "div",
        { staticClass: "massec-import" },
        [
          _c("import-excel", {
            attrs: {
              url: `Inventory/import/massec?InventoryHeaderId=${_vm.inventoryInfo.id}`,
              iconName: "icon-massec",
              isTypeMassec: true,
              id: "inventory-import-massec-excel",
              componentName: "inventory",
            },
            on: { success: _vm.importConfirmAction },
          }),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }