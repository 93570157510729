var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("blured-background", [
    _c("div", { staticClass: "imported-inventory-counts" }, [
      _c(
        "div",
        { staticClass: "imported-inventory-counts-header" },
        [
          _c("Icon", {
            staticClass: "imported-inventory-counts-header-icon",
            attrs: { name: "icon-global-refresh-data" },
          }),
          _c(
            "label",
            { staticClass: "imported-inventory-counts-header-title" },
            [_vm._v(_vm._s(_vm.$t("Massec.Import_Header_Title")))]
          ),
          _c(
            "label",
            { staticClass: "imported-inventory-counts-header-desc" },
            [_vm._v(_vm._s(_vm.$t("Massec.Import_Header_Description")))]
          ),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "imported-inventory-counts-table" },
        [
          _c("TableView", {
            attrs: {
              componentName: "MassecTable",
              fields: _vm.fields,
              data: _vm.countList,
              tableName: _vm.$t("Stocks.tableName"),
              "read-only": true,
            },
          }),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "imported-inventory-counts-buttons" },
        [
          _c(
            "Button",
            {
              staticClass: "imported-inventory-counts-button yes",
              attrs: {
                primary: "",
                size: "medium",
                isLoading: _vm.isLoadingUpdate,
                disabled: _vm.isLoadingUpdate,
              },
              on: { click: _vm.update },
            },
            [_vm._v(_vm._s(_vm.$t("Massec.Import_Button_Yes")))]
          ),
          _c(
            "Button",
            {
              staticClass: "imported-inventory-counts-button no",
              on: { click: _vm.close },
            },
            [_vm._v(_vm._s(_vm.$t("Massec.Import_Button_No")))]
          ),
        ],
        1
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }