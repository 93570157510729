var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "Popup",
    [
      _c("template", { slot: "title" }, [
        _vm._v(_vm._s(_vm.$t("Inventory.addDetail_Header"))),
        _c("div", { staticClass: "PopupHeader" }, [
          _c(
            "div",
            { staticClass: "tab" },
            [
              _c(
                "Button",
                {
                  class: { isSelected: _vm.activeTabIndex === 0 },
                  attrs: {
                    size: "xsmall",
                    id: "btn-recipes-popup-tab-header-1",
                    type: "button",
                  },
                  on: {
                    click: function ($event) {
                      return _vm.tabChange(0)
                    },
                  },
                },
                [
                  _vm._v(
                    _vm._s(_vm.$t("Inventory.addDetail_Header_Tab_StockItem"))
                  ),
                ]
              ),
              _c(
                "Button",
                {
                  class: { isSelected: _vm.activeTabIndex === 1 },
                  attrs: {
                    size: "xsmall",
                    id: "btn-recipes-popup-tab-header-2",
                    type: "button",
                  },
                  on: {
                    click: function ($event) {
                      return _vm.tabChange(1)
                    },
                  },
                },
                [
                  _vm._v(
                    _vm._s(_vm.$t("Inventory.addDetail_Header_Tab_Recipe"))
                  ),
                ]
              ),
            ],
            1
          ),
        ]),
      ]),
      _c("template", { slot: "content" }, [
        _c(
          "form",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.activeTabIndex === 0,
                expression: "activeTabIndex === 0",
              },
            ],
            staticClass: "Form",
            attrs: { id: "inventory-add-stock-item-form" },
            on: {
              submit: function ($event) {
                $event.preventDefault()
                return _vm.onSubmit("add-stock")
              },
            },
          },
          [
            _c("div", { staticClass: "Form-item required" }, [
              _c("label", { staticClass: "Form-item-label" }, [
                _vm._v(
                  _vm._s(_vm.$t("Inventory.addDetail_Header_StockItem_Label"))
                ),
              ]),
              _c(
                "div",
                { staticClass: "control" },
                [
                  _c(
                    "custom-search",
                    {
                      directives: [
                        {
                          name: "validate",
                          rawName: "v-validate",
                          value: "required",
                          expression: "'required'",
                        },
                      ],
                      ref: "firstInput",
                      attrs: {
                        "component-name":
                          "select-stock-item-add-stock-item-popup",
                        name: "stockItem",
                        "data-vv-as": "Stok Malı",
                        "data-vv-scope": "add-stock",
                        inputClass: {
                          "is-danger": _vm.veeErrors.has("add-stock.stockItem"),
                        },
                        "on-search": _vm.searchStockItems,
                        options: _vm.searchedStockItems,
                        label: "name",
                        loading: _vm.isSearchStockItem,
                        placeholder: _vm.$t(
                          "Inventory.addDetail_Header_StockItem_Placeholder"
                        ),
                        directSelectOption: _vm.directSelectOption,
                      },
                      on: { input: _vm.selectStockItem },
                      model: {
                        value: _vm.form.stockItem,
                        callback: function ($$v) {
                          _vm.$set(_vm.form, "stockItem", $$v)
                        },
                        expression: "form.stockItem",
                      },
                    },
                    [
                      _c("template", { slot: "no-options" }, [
                        _vm._v(
                          _vm._s(
                            _vm.$t(
                              "Inventory.addDetail_Header_StockItem_NoData"
                            )
                          )
                        ),
                      ]),
                    ],
                    2
                  ),
                ],
                1
              ),
              _c(
                "p",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.veeErrors.has("add-stock.stockItem"),
                      expression: "veeErrors.has('add-stock.stockItem')",
                    },
                  ],
                  staticClass: "Form-item-help is-danger",
                },
                [_vm._v(_vm._s(_vm.veeErrors.first("add-stock.stockItem")))]
              ),
            ]),
            _c("div", { staticClass: "Form-item required" }, [
              _c("label", { staticClass: "Form-item-label" }, [
                _vm._v(_vm._s(_vm.$t("Inventory.addDetail_Header_Unit_Label"))),
              ]),
              _c("div", { staticClass: "control with-icon" }, [
                _c(
                  "div",
                  { staticClass: "form-item-select" },
                  [
                    _c("Icon", {
                      staticClass: "icon-down-arrow",
                      attrs: { name: "icon-down-arrow" },
                    }),
                    _c(
                      "select",
                      {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.form.unitId,
                            expression: "form.unitId",
                          },
                          {
                            name: "validate",
                            rawName: "v-validate",
                            value: "required",
                            expression: "'required'",
                          },
                        ],
                        staticClass: "select",
                        class: {
                          "is-danger": _vm.veeErrors.has("add-stock.unitId"),
                        },
                        attrs: {
                          id: "select-unit-add-stock-item-popup",
                          name: "unitId",
                          "data-vv-scope": "add-stock",
                          disabled: _vm.filteredUnits.length === 0,
                        },
                        on: {
                          change: [
                            function ($event) {
                              var $$selectedVal = Array.prototype.filter
                                .call($event.target.options, function (o) {
                                  return o.selected
                                })
                                .map(function (o) {
                                  var val = "_value" in o ? o._value : o.value
                                  return val
                                })
                              _vm.$set(
                                _vm.form,
                                "unitId",
                                $event.target.multiple
                                  ? $$selectedVal
                                  : $$selectedVal[0]
                              )
                            },
                            function ($event) {
                              return _vm.addCountingItem({
                                unitId: _vm.form.unitId,
                              })
                            },
                          ],
                        },
                      },
                      [
                        _c(
                          "option",
                          {
                            attrs: {
                              value: "-1",
                              id: "option-unit-add-stock-item-popup-unselected",
                            },
                          },
                          [
                            _vm._v(
                              _vm._s(
                                _vm.$t(
                                  "ListsInventory.formField_Unit_Unselected"
                                )
                              )
                            ),
                          ]
                        ),
                        _vm._l(_vm.filteredUnits, function (unit) {
                          return _c(
                            "option",
                            {
                              key: unit.id,
                              attrs: {
                                disabled: unit.isUsed,
                                id: `option-unit-add-stock-item-popup-${unit.name}`,
                              },
                              domProps: { value: unit.id },
                            },
                            [_vm._v(_vm._s(unit.name))]
                          )
                        }),
                      ],
                      2
                    ),
                  ],
                  1
                ),
              ]),
              _c(
                "p",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.veeErrors.has("add-stock.unitId"),
                      expression: "veeErrors.has('add-stock.unitId')",
                    },
                  ],
                  staticClass: "Form-item-help is-danger",
                },
                [_vm._v(_vm._s(_vm.veeErrors.first("add-stock.unitId")))]
              ),
            ]),
            _c(
              "div",
              { staticClass: "selectedItemsTable" },
              _vm._l(_vm.form.countings, function (item, index) {
                return _c("div", { key: index, staticClass: "selectedItems" }, [
                  _c("div", { staticClass: "selectedItem" }, [
                    _c("span", { staticClass: "itemName" }, [
                      _vm._v(_vm._s(item.name)),
                      _c(
                        "div",
                        {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value: _vm.veeErrors.has(
                                `add-stock.quantity-${index}`
                              ),
                              expression:
                                "veeErrors.has(`add-stock.quantity-${index}`)",
                            },
                          ],
                          staticClass: "selectedItemHelperText is-danger",
                        },
                        [
                          _vm._v(
                            _vm._s(
                              _vm.veeErrors.first(`add-stock.quantity-${index}`)
                            )
                          ),
                        ]
                      ),
                    ]),
                    _c(
                      "span",
                      { staticClass: "selectedInputSpan align-right" },
                      [
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model.number",
                              value: _vm.form.countings[index].quantity,
                              expression: "form.countings[index].quantity",
                              modifiers: { number: true },
                            },
                            {
                              name: "validate",
                              rawName: "v-validate",
                              value: {
                                required: true,
                                decimal: _vm.$getConst("DECIMAL_FOR_QUANTITY"),
                                greater_than: 0,
                                max: _vm.$getConst("MAX_DIGITS_FOR_QUANTITY"),
                              },
                              expression:
                                "{required: true, decimal: $getConst('DECIMAL_FOR_QUANTITY'), greater_than: 0, max: $getConst('MAX_DIGITS_FOR_QUANTITY')}",
                            },
                          ],
                          staticClass:
                            "txt quantity ff-mono no-spinners selectedInput",
                          class: {
                            "is-danger": _vm.veeErrors.has(
                              `add-stock.quantity-${index}`
                            ),
                          },
                          attrs: {
                            id: `selected-units-input-add-stock-item-popup`,
                            name: `quantity-${index}`,
                            "data-vv-as": "Miktar",
                            "data-vv-scope": "add-stock",
                            type: "number",
                            step: "any",
                          },
                          domProps: {
                            value: _vm.form.countings[index].quantity,
                          },
                          on: {
                            input: function ($event) {
                              if ($event.target.composing) return
                              _vm.$set(
                                _vm.form.countings[index],
                                "quantity",
                                _vm._n($event.target.value)
                              )
                            },
                            blur: function ($event) {
                              return _vm.$forceUpdate()
                            },
                          },
                        }),
                      ]
                    ),
                    _c(
                      "span",
                      {
                        staticClass: "deleteButton",
                        on: {
                          click: function ($event) {
                            return _vm.removeCountingItem(item.id)
                          },
                        },
                      },
                      [
                        _c("Icon", {
                          staticClass: "m-left-5",
                          attrs: {
                            name: "icon-popup-close",
                            id: `selected-stock-item-delete-${index}`,
                          },
                        }),
                      ],
                      1
                    ),
                  ]),
                ])
              }),
              0
            ),
          ]
        ),
        _c(
          "form",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.activeTabIndex === 1,
                expression: "activeTabIndex === 1",
              },
            ],
            staticClass: "Form",
            attrs: { id: "inventory-add-recipe-item-form" },
            on: {
              submit: function ($event) {
                $event.preventDefault()
                return _vm.onSubmit("add-recipe")
              },
            },
          },
          [
            _c("div", { staticClass: "Form-item required" }, [
              _c("label", { staticClass: "Form-item-label" }, [
                _vm._v(
                  _vm._s(_vm.$t("Inventory.addDetail_Header_Recipe_Label"))
                ),
              ]),
              _c(
                "div",
                { staticClass: "control" },
                [
                  _c(
                    "custom-search",
                    {
                      directives: [
                        {
                          name: "validate",
                          rawName: "v-validate",
                          value: "required",
                          expression: "'required'",
                        },
                      ],
                      ref: "secondInput",
                      attrs: {
                        "component-name": "select-recipe-add-recipe-popup",
                        name: "recipeItem",
                        "data-vv-as": "Reçete",
                        "data-vv-name": "recipeItem",
                        "data-vv-scope": "add-recipe",
                        inputClass: {
                          "is-danger": _vm.veeErrors.has(
                            "add-recipe.recipeItem"
                          ),
                        },
                        "on-search": _vm.searchRecipes,
                        options: _vm.searchedRecipes,
                        label: "name",
                        loading: _vm.isSearchRecipes,
                        placeholder: _vm.$t(
                          "Inventory.addDetail_Header_Recipe_Placeholder"
                        ),
                        directSelectOption: _vm.recipeDirectSelectOption,
                      },
                      on: { input: _vm.selectRecipe },
                      model: {
                        value: _vm.form.recipe,
                        callback: function ($$v) {
                          _vm.$set(_vm.form, "recipe", $$v)
                        },
                        expression: "form.recipe",
                      },
                    },
                    [
                      _c("template", { slot: "no-options" }, [
                        _vm._v(
                          _vm._s(
                            _vm.$t("Inventory.addDetail_Header_Recipe_NoData")
                          )
                        ),
                      ]),
                    ],
                    2
                  ),
                  _vm.form.recipe
                    ? _c("div", { staticClass: "Form-item portion-info" }, [
                        _c("span", { staticClass: "title c-success" }, [
                          _vm._v(
                            _vm._s(
                              _vm.$t("Inventory.addDetail_Header_PortionInfo")
                            )
                          ),
                        ]),
                        _c("span", [_vm._v(_vm._s(_vm.portionQuantity))]),
                      ])
                    : _vm._e(),
                  _c(
                    "p",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.veeErrors.has("add-recipe.recipeItem"),
                          expression: "veeErrors.has('add-recipe.recipeItem')",
                        },
                      ],
                      staticClass: "Form-item-help is-danger",
                    },
                    [
                      _vm._v(
                        _vm._s(_vm.veeErrors.first("add-recipe.recipeItem"))
                      ),
                    ]
                  ),
                ],
                1
              ),
            ]),
            _c("div", { staticClass: "Form-item required" }, [
              _c("label", { staticClass: "Form-item-label" }, [
                _vm._v(
                  _vm._s(_vm.$t("Inventory.addDetail_Header_RecipeQuantity"))
                ),
              ]),
              _c("div", { staticClass: "control" }, [
                _c("input", {
                  directives: [
                    {
                      name: "validate",
                      rawName: "v-validate",
                      value: {
                        required: true,
                        decimal: _vm.$getConst("DECIMAL_FOR_QUANTITY"),
                        greater_than: 0,
                        max: _vm.$getConst("MAX_DIGITS_FOR_QUANTITY"),
                      },
                      expression:
                        "{required: true, decimal: $getConst('DECIMAL_FOR_QUANTITY'), greater_than: 0, max: $getConst('MAX_DIGITS_FOR_QUANTITY')}",
                    },
                    {
                      name: "model",
                      rawName: "v-model.number",
                      value: _vm.form.actualQuantity,
                      expression: "form.actualQuantity",
                      modifiers: { number: true },
                    },
                  ],
                  staticClass: "txt quantity ff-mono no-spinners",
                  class: {
                    "is-danger": _vm.veeErrors.has("add-recipe.actualQuantity"),
                  },
                  attrs: {
                    id: "input-recipes-popup-quanity",
                    type: "number",
                    name: "actualQuantity",
                    "data-vv-as": "Miktar",
                    "data-vv-scope": "add-recipe",
                    autocomplete: "off",
                    step: "any",
                  },
                  domProps: { value: _vm.form.actualQuantity },
                  on: {
                    input: function ($event) {
                      if ($event.target.composing) return
                      _vm.$set(
                        _vm.form,
                        "actualQuantity",
                        _vm._n($event.target.value)
                      )
                    },
                    blur: function ($event) {
                      return _vm.$forceUpdate()
                    },
                  },
                }),
              ]),
              _c(
                "p",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.veeErrors.has("add-recipe.actualQuantity"),
                      expression: "veeErrors.has('add-recipe.actualQuantity')",
                    },
                  ],
                  staticClass: "Form-item-help is-danger",
                },
                [
                  _vm._v(
                    _vm._s(_vm.veeErrors.first("add-recipe.actualQuantity"))
                  ),
                ]
              ),
            ]),
          ]
        ),
        _vm.isRecipeAddedBefore
          ? _c(
              "div",
              { staticClass: "Form-item" },
              [
                _c("DualRadioButton", {
                  attrs: {
                    isActive: _vm.isOverwrite,
                    isActiveLabel: _vm.$t(
                      "Inventory.Add_Detail_Overwrite_Active"
                    ),
                    isPassiveLabel: _vm.$t(
                      "Inventory.Add_Detail_Overwrite_Passive"
                    ),
                    headerLabel: _vm.$t(
                      "Inventory.Add_Detail_Overwrite_Header"
                    ),
                  },
                  on: { delegateIsActiveValue: _vm.changeOverwrite },
                }),
              ],
              1
            )
          : _vm._e(),
      ]),
      _c(
        "template",
        { slot: "footer" },
        [
          _c(
            "div",
            { staticClass: "add-other-items-checkbox-container" },
            [
              _c("CustomCheckbox", {
                attrs: {
                  id: "checkbox-add-stock-item-popup",
                  label: _vm.$t(
                    "Inventory.addDetail_Header_Checkbox_AddAnotherData"
                  ),
                },
                model: {
                  value: _vm.saveAndNew,
                  callback: function ($$v) {
                    _vm.saveAndNew = $$v
                  },
                  expression: "saveAndNew",
                },
              }),
            ],
            1
          ),
          _c(
            "Button",
            {
              staticClass: "uppercase",
              attrs: {
                primary: "",
                type: "submit",
                size: "medium",
                variant: "full",
                disabled: _vm.isRunAction,
                isLoading: _vm.isRunAction,
                id: "button-list-inventory-add-detail",
                form:
                  _vm.activeTabIndex === 0
                    ? "inventory-add-stock-item-form"
                    : "inventory-add-recipe-item-form",
              },
            },
            [
              _c("span", [
                _vm._v(
                  _vm._s(_vm.$t("Inventory.addDetail_Header_AddButton_Label"))
                ),
              ]),
            ]
          ),
        ],
        1
      ),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }