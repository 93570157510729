import requestHandler from '@/utils/requestHandler'
import HTTP from '@/utils/http'
import { Inventories, Inventory } from '../model'
import InventoryShelfList from '../model/shelf-list'
import { isEmpty } from '@/utils/baseOperations'
import { getPaginationNumber } from '@/utils/pagination'

export default {
  namespaced: true,

  state: {
    inventories: new Inventories(),
    inventory: null,
    inventoryHeader: null,
    inventoryRecipeCountList: [],
    selectedListType: [],
    selectedList: [],
    inventoryPage: {
      number: 1,
      size: 20,
      total: 0
    },
    inventoryListPage: {
      number: 1,
      size: 50,
      total: 0
    },
    inventoryShelfPage: {
      number: 1,
      size: 50,
      total: 0
    },
    inventoriesListPage: {
      number: 1,
      size: 20,
      total: 0
    },
    inventoriesBatchPage: {
      number: 1,
      size: 20,
      total: 0
    },
    inventoryShelfList: new InventoryShelfList(),
    detailList: [],
    shelfListDetail: [],
    inventoryBatchList: []
  },

  getters: {
    inventories_List: state => state.inventories.list,
    inventories_IsEmpty: state => state.inventories.length === 0,
    inventory_Detail: state => state.inventory,
    inventory_IsEmpty: state => isEmpty(state.inventory),
    inventory_Header: state => state.inventoryHeader,
    inventoryRecipeCountList: state => state.inventoryRecipeCountList,
    inventoryPage: state => ({
      ...state.inventoryPage,
      last: Math.ceil(state.inventoryPage.total / state.inventoryPage.size)
    }),
    inventoryListPage: state => ({
      ...state.inventoryListPage,
      last: Math.ceil(state.inventoryListPage.total / state.inventoryListPage.size)
    }),
    InventoryBatchPage: state => ({
      ...state.inventoriesBatchPage,
      last: Math.ceil(state.inventoriesBatchPage.total / state.inventoriesBatchPage.size)
    }),
    InventoryShelfPage: state => ({
      ...state.inventoryShelfPage,
      last: Math.ceil(state.inventoryShelfPage.total / state.inventoryShelfPage.size)
    }),
    inventoriesListPage: state => ({
      ...state.inventoriesListPage,
      last: Math.ceil(state.inventoriesListPage.total / state.inventoriesListPage.size)
    }),
    selectedList: state => state.selectedList,
    selectedListType: state => state.selectedListType,
    InventoryShelfList: state => state.inventoryShelfList,
    InventoryBatchList: state => state.inventoryBatchList,
    ShelfListDetail: state => state.shelfListDetail,
    detailList: state => state.detailList
  },

  actions: {
    async get_INVENTORY_LIST({ state, commit, dispatch }, { payload }) {
      const PageNumber = getPaginationNumber({ selectedPage: payload.page, currentPage: state.inventoriesListPage.number })
      const params = {
        ...payload,
        storeList: payload.storeList.map(store => store.id),
        pageNumber: PageNumber,
        pageSize: payload.pageSize === 0 ? 0 : payload.pageSize,
      }
      const results = await requestHandler({
        title: 'get_INVENTORY_LIST',
        methodName: 'get_INVENTORY_LIST',
        method: () => {
          return HTTP.post('Inventory/header/all', params)
        },
        success: result => {
          commit('RESET_INVENTORY_HEADER')
          commit('UPDATE_INVENTORY_LIST', result.data)
          return result
        }
      })
      return results
    },

    async create_INVENTORY_HEADER({ commit, dispatch }, inventory) {
      const results = await requestHandler({
        title: 'create_INVENTORY_HEADER',
        methodName: 'create_INVENTORY_HEADER',
        method: () => {
          return HTTP.post('Inventory/header', inventory)
        },
        success: result => result
      })
      return results
    },

    async delete_INVENTORY({ dispatch, commit }, InventoryHeaderId) {
      const results = await requestHandler({
        title: 'delete_INVENTORY',
        methodName: 'delete_INVENTORY',
        method: () => {
          return HTTP.delete('Inventory', {
            params: { InventoryHeaderId }
          })
        },
        success: result => {
          commit('RESET_INVENTORY_HEADER')
          return result
        }
      })
      return results
    },

    async close_INVENTORY({ dispatch }, inventoryHeaderId) {
      const results = await requestHandler({
        title: 'close_INVENTORY',
        methodName: 'close_INVENTORY',
        method: () => {
          return HTTP.put('Inventory/close', { inventoryHeaderId })
        },
        success: result => result
      })
      return results
    },

    async get_INVENTORY({ state, commit, dispatch }, payload) {
      const PageNumber = getPaginationNumber({ selectedPage: payload.pageNumber, currentPage: state.inventoryPage.number })
      const params = {
        ...payload,
        pageNumber: PageNumber,
        pageSize: payload.pageSize === 0 ? 0 : payload.pageSize // set 0 for get all list items
      }

      const results = await requestHandler({
        title: 'get_INVENTORY',
        methodName: 'get_INVENTORY',
        method: () => {
          return HTTP.post('Inventory', {
            ...params
          })
        },
        success: result => {
          commit('UPDATE_INVENTORY_DETAIL', result.data)
          return result
        }
      })
      return results
    },

    async get_inventory_item({ commit }, Id) {
      const results = await requestHandler({
        title: 'get_inventory_item',
        methodName: 'get_inventory_item',
        method: () => {
          return HTTP.get('Inventory/header', {params: { Id }})
        },
        success: result => result
      })
      return results
    },

    async get_RECIPE_INVENTORY({ commit, dispatch }, params) {
      const results = await requestHandler({
        title: 'get_RECIPE_INVENTORY',
        methodName: 'get_RECIPE_INVENTORY',
        method: () => {
          return HTTP.post('Inventory/recipecount/getlistbyinventoryheaderid', params)
        },
        success: result => {
          const list = result.data.inventoryRecipeCountList.map(item => {
            return {
              ...item,
              unitNameWithQuantity: item.unitQuantity + ' ' + item.unitName
            }
          })
          commit('UPDATE_INVENTORY_RECIPE_COUNT_LIST', list)
          return result
        }
      })
      return results
    },

    async sendRecipeCount({ commit, dispatch }, payload) {
      const results = await requestHandler({
        title: 'addRecipeCount',
        methodName: 'addRecipeCount',
        method: () => {
          return HTTP.post('Inventory/count/force/recipe', payload)
        },
        success: result => {
          return result
        }
      })
      return results
    },

    async getInventoryListByShelf({ commit, dispatch }, payload) {
      const results = await requestHandler({
        title: 'getInventoryListByShelf',
        methodName: 'getInventoryListByShelf',
        method: () => {
          return HTTP.post('Inventory/shelfstoragelocationlist', payload)
        },
        success: result => {
          commit('UPDATE_INVENTORY_SHELF_PAGE', result.data)
          return result
        }
      })
      return results
    },

    async inventoryShelfUnitCount({ commit, dispatch }, payload) {
      const results = await requestHandler({
        title: 'inventoryShelfUnitCount',
        methodName: 'inventoryShelfUnitCount',
        method: () => {
          return HTTP.post('Inventory/count', payload.params)
        },
        success: result => {
          commit('UPDATE_INVENTORY_SHELF_UNIT_COUNT', {data: result.data, item: payload.item})
          return result
        }
      })
      return results
    },

    async updateInventoryShelfUnitCount({ commit, dispatch }, payload) {
      const results = await requestHandler({
        title: 'updateInventoryShelfUnitCount',
        methodName: 'updateInventoryShelfUnitCount',
        method: () => {
          return HTTP.put('Inventory/count', payload.params)
        },
        success: result => {
          commit('UPDATE_INVENTORY_SHELF_UNIT_COUNT', {data: result.data, item: payload.item})
          return result
        }
      })
      return results
    },

    async create_INVENTORY_UNIT_COUNT({ commit, dispatch }, obj) {
      const results = await requestHandler({
        title: 'create_INVENTORY_UNIT_COUNT',
        methodName: 'create_INVENTORY_UNIT_COUNT',
        method: () => {
          return HTTP.post('Inventory/count', obj.params)
        },
        success: result => {
          if (!obj.inventoryListHeaderId && !obj.notCommit) {
            commit('UPDATE_INVENTORY_UNIT_COUNT', {
              stockItemId: obj.stockItemId,
              unitId: obj.unitId,
              data: result.data
            })
          }
          return result
        }
      })
      return results
    },

    async update_INVENTORY_UNIT_COUNT({ commit, dispatch }, obj) {
      const results = await requestHandler({
        title: 'update_INVENTORY_UNIT_COUNT',
        methodName: 'update_INVENTORY_UNIT_COUNT',
        method: () => {
          return HTTP.put('Inventory/count', obj.params)
        },
        success: result => {
          if (!obj.inventoryListHeaderId && !obj.notCommit) {
            commit('UPDATE_INVENTORY_UNIT_COUNT', {
              stockItemId: obj.stockItemId,
              unitId: obj.unitId,
              data: result.data
            })
          }
          return result
        }
      })
      return results
    },

    async getOpenInventoryLists({ state, commit }, payload) {
      const PageNumber = payload.pageNumber === 'prev'
      ? state.inventoryListPage.number - 1
      : payload.pageNumber === 'next'
        ? state.inventoryListPage.number + 1
        : payload.pageNumber
      const results = await requestHandler({
        title: 'getOpenInventoryLists',
        methodName: 'getOpenInventoryLists',
        method: () => {
          return HTTP.post('Inventory/list', {
            ...payload,
            pageNumber: PageNumber,
            pageSize: payload.pageSize || state.inventoryListPage.size
          })
        },
        success: result => {
          commit('SET_OPEN_INVENTORY_LISTS', result.data)
          commit('UPDATE_INVENTORY_DETAIL', {
            ...result.data,
            inventoryItemList: result.data.inventoryListHeaderList.length > 0 ? result.data.inventoryListHeaderList[0].inventoryItemList : []
          })
          return result
        }
      })
      return results
    },
    async getSpotListHeaderInfo({ commit }, InventoryHeaderId) {
      const results = await requestHandler({
        title: 'get_INVENTORY',
        methodName: 'get_INVENTORY',
        method: () => {
          return HTTP.get('InventoryList/header/spotcheck/inventory', {
            params: { InventoryHeaderId }
          })
        },
        success: result => {
          commit('UPDATE_INVENTORY_DETAIL', result.data)
          return result
        }
      })
      return results
    },
    async checkInventoryAll({ commit }, payload) {
      const results = await requestHandler({
        title: 'checkInventoryAll',
        methodName: 'checkInventoryAll',
        method: () => {
          return HTTP.post('Inventory/check', payload)
        },
        success: result => {
          return result
        }
      })
      return results
    },
    async getInventoryCountingByStockItemId({ commit }, { inventoryHeaderId, stockItemId }) {
      const results = await requestHandler({
        title: 'getInventoryCountingByStockItemId',
        methodName: 'getInventoryCountingByStockItemId',
        method: () => {
          return HTTP.post('Inventory/count/getbystockitemid', {
            inventoryHeaderId,
            stockItemId
          })
        },
        success: result => result
      })
      return results
    },
    async upsertClosedInventoryCount({ commit, dispatch }, { inventoryHeaderId, stockItemId, unitCountList }) {
      const results = await requestHandler({
        title: 'upsertClosedInventoryCount',
        methodName: 'upsertClosedInventoryCount',
        method: () => {
          return HTTP.post('Inventory/count/upsert', { inventoryHeaderId, stockItemId, unitCountList })
        },
        success: result => {
          return result
        }
      })
      return results
    },
    async upsertClosedInventoryRecipeCount({ commit, dispatch }, payload) {
      const results = await requestHandler({
        title: 'upsertClosedInventoryRecipeCount',
        methodName: 'upsertClosedInventoryRecipeCount',
        method: () => {
          return HTTP.post('Inventory/recipecount/upsert', payload)
        },
        success: result => {
          return result
        }
      })
      return results
    },
    async addStockItemToInventory({ commit }, { inventoryHeaderId, stockItemId, inventoryCountDetailList }) {
      const results = await requestHandler({
        title: 'addStockItemToInventory',
        methodName: 'addStockItemToInventory',
        method: () => {
          return HTTP.post('Inventory/count/force', {
            inventoryHeaderId,
            stockItemId,
            inventoryCountDetailList
          })
        },
        success: result => {
          return result
        }
      })
      return results
    },
    async addRecipeToInventory({ commit }, { inventoryHeaderId, recipeHeaderId, actualQuantity, isOverwrite }) {
      const results = await requestHandler({
        title: 'addRecipeToInventory',
        methodName: 'addRecipeToInventory',
        method: () => {
          return HTTP.post('Inventory/count/force/recipe', {
            inventoryHeaderId,
            recipeHeaderId,
            actualQuantity,
            isOverwrite
          })
        },
        success: result => result
      })
      return results
    },

    async update_IMPORT_INVENTORY_MASSEC({ commit, dispatch }, params) {
      const loadingName = 'update_IMPORT_INVENTORY_MASSEC'
      const results = await requestHandler({
        title: loadingName,
        methodName: loadingName,
        method: () => {
          return HTTP.put('Inventory/count/list/massec', params)
        },
        success: result => result
      })
      return results
    },

    async getInventoryBatchList({ commit, dispatch }, payload) {
      const results = await requestHandler({
        title: 'getInventoryBatchList',
        methodName: 'getInventoryBatchList',
        method: () => {
          return HTTP.post('Inventory/batch', payload)
        },
        success: result => {
          commit('UPDATE_INVENTORY_BATCH_LIST', result.data)
          return result
        }
      })
      return results
    },

    async updateInventoryBatchCount({ commit, dispatch }, params) {
      const loadingName = 'updateInventoryBatchCount'
      const results = await requestHandler({
        title: loadingName,
        methodName: loadingName,
        method: () => {
          return HTTP.post('Inventory/count/batch', params)
        },
        success: result => result
      })
      return results
    },

    async createInventoryBatch({ commit, dispatch }, params) {
      const loadingName = 'createInventoryBatch'
      const results = await requestHandler({
        title: loadingName,
        methodName: loadingName,
        method: () => {
          return HTTP.post('Batch/inventory', params)
        },
        success: result => result
      })
      return results
    }
  },

  mutations: {
    SET_OPEN_INVENTORY_LISTS(state, data) {
      state.inventoryListPage.number = data.pageNumber
      if (data.pageSize <= 100) state.inventoryListPage.size = data.pageSize
      state.inventoryListPage.total = data.totalCount
    },

    RESET(state) {
      state.inventories = new Inventories()
      state.inventory = null
      state.closedInventory = null
    },

    RESET_INVENTORY_HEADER(state) {
      state.inventory = null
    },

    UPDATE_INVENTORY_LIST(state, data) {
      state.inventories.addItems(data.inventoryHeaderList)
      state.inventoriesListPage.number = data.pageNumber
      state.inventoriesListPage.size = data.pageSize
      state.inventoriesListPage.total = data.totalCount
    },

    UPDATE_INVENTORY_DETAIL(state, data) {
      state.inventory = new Inventory(data.inventoryHeader)
      state.inventoryHeader = data.inventoryHeader
      state.inventoryPage.total = data.totalCount
      state.inventoryPage.number = data.pageNumber
      if (data.pageSize <= 100) state.inventoryPage.size = data.pageSize
      if (!isEmpty(data.inventoryDetailList)) {
        state.inventory.addItems(data.inventoryDetailList)
      } else if (!isEmpty(data.inventoryItemList)) {
        state.inventory.addItems(data.inventoryItemList)
      }
    },

    UPDATE_INVENTORY_UNIT_COUNT(state, { stockItemId, unitId, data }) {
      const detail = state.inventory.list.find(detail => {
        return detail.stockItemId === stockItemId
      })
      const unitCount = detail.unitCountList.find(unit => unit.unitId === unitId)
      const additionalUnitData = Object.assign({}, data, {
        inventoryCountId: data.id,
        unitQuantity: data.unitQuantity
      })
      Object.assign(unitCount, additionalUnitData)
    },

    STATUS_HELPER_BOX(state, Id) {
      let inventory = state.inventories.findItem(Id)
      inventory.helperBox = !inventory.helperBox
    },

    HIDE_HELPER_BOX(state) {
      state.inventories.list.forEach(inventory => {
        inventory.helperBox = false
      })
    },

    UPDATE_INVENTORY_RECIPE_COUNT_LIST(state, list) {
      state.inventoryRecipeCountList = list
    },
    UPDATE_LIST_TYPE_FILTER(state, data) {
      state.selectedListType = data
    },
    UPDATE_LIST_FILTER(state, data) {
      state.selectedList = data
    },
    UPDATE_INVENTORY_SHELF_UNIT_COUNT(state, {data, item}) {
      const shelfListItem = state.inventoryShelfList.list[0].inventoryItemList.find(i => i.stockItemId === item.stockItemId)
      shelfListItem.unitCountList.find(el => {
        el.unitQuantity = data.unitQuantity
      })
      item.totalUnitQuantity = item.unitQuantity * item.baseQuantity
    },
    UPDATE_INVENTORY_SHELF_PAGE(state, data) {
      state.inventoryShelfList.addItems(data.shelfStorageLocationListHeaderList)
    },
    SHOW_DETAIL(state, item) {
      let shelfList = state.inventoryShelfList.list.find(i => i.shelfStorageLocationListHeaderId === item.shelfStorageLocationListHeaderId)
      if (shelfList) {
        shelfList.addDetails(item.inventoryItemList)
        shelfList.showDetail = true
      }
      item.showDetail = true
    },
    HIDE_DETAIL(state, item) {
      item.showDetail = false
    },
    UPDATE_INVENTORY_BATCH_LIST(state, data) {
      state.inventoryBatchList = [...data.batchHeaderList]
      state.inventoryBatchList.forEach(i => i.showDetail = false)
      state.inventoriesBatchPage.total = data.totalCount
      state.inventoriesBatchPage.number = data.pageNumber
    },
    SHOW_BATCH_DETAIL(state, item) {
      item.detailList = [...item.batchDetailList]
      item.showDetail = true
    },
    HIDE_BATCH_DETAIL(state, item) {
      item.showDetail = false
    }
  }
}
