<template lang="pug">
DeleteAlert(
  :item="item",
  :fields="fields",
  :tableName="$t('Inventories.popupRemove_TableName')",
  loading="itemRemoveInventory",
  @delegateOnAlertRemoveOk="itemRemove")
</template>

<script>
import { mapActions } from 'vuex'
import moment from 'moment'
import { vueWaitLoader } from '@/utils/baseOperations'

export default {
  name: 'InventoryRemove',

  data () {
    return {
      fields: [{
        name: 'storeName',
        is_visible: true,
        short_title: this.$t('Inventories.popupAdd_FormField_Store_Short'),
        title: this.$t('Inventories.popupAdd_FormField_Store')
      }, {
        name: 'inventoryDate',
        is_visible: true,
        short_title: this.$t('Inventories.popupRemove_TableColumn_InventoryDate_Short'),
        title: this.$t('Inventories.popupRemove_TableColumn_InventoryDate')
      }],
      item: {}
    }
  },

  async mounted () {
    if (this.$route.params.item) {
      this.item = {
        ...this.$route.params.item
      }
      this.item.inventoryDate = moment(this.item.inventoryDate).format('DD/MM/YYYY')
    } else {
      this.get_inventory_item(this.$route.params.id)
        .then(res => {
          this.item = {
            ...res.data.inventoryHeader
          }
          this.item.inventoryDate = moment(res.data.inventoryHeader.inventoryDate).format('DD/MM/YYYY')
        })
    }
  },

  methods: {
    ...mapActions('Notify', [
      'notifyShow'
    ]),

    ...mapActions('Inventories', [
      'get_INVENTORY_LIST',
      'delete_INVENTORY',
      'get_inventory_item'
    ]),

    close () {
      this.$router.push({
        name: this.$route.matched[0].name
      })
    },

    itemRemove: vueWaitLoader(async function () {
      await this.delete_INVENTORY(this.item.id).then(res => {
        if (res) {
          const message = this.$t('Inventories.notification_Deleted')
          this.notifyShow({ message })
          this.get_INVENTORY_LIST({
            storeList: [],
            includeClosed: this.$route.params.includeClosed
          })
          this.$emit('getList')
          this.close()
        }
      })
    }, 'itemRemoveInventory')
  }
}
</script>

<style lang="scss">
</style>
