var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "Popup",
    { attrs: { status: _vm.status }, on: { buttonClosePopup: _vm.close } },
    [
      _c("template", { slot: "title" }, [
        _vm._v(_vm._s(_vm.$t("Inventories.popupCountingEdit_Title"))),
      ]),
      _c("template", { slot: "detail" }, [
        _c("div", { staticClass: "Popup-info" }, [
          _vm._v(_vm._s(_vm.inventoryTitle)),
        ]),
      ]),
      _c(
        "template",
        { slot: "content" },
        [
          _c(
            "div",
            { staticClass: "selectType" },
            [
              _vm.inventory.type !== 2
                ? _c("SelectedButtons", {
                    attrs: {
                      data: [
                        {
                          text: _vm.$t(
                            "Inventory.addDetail_Header_Tab_StockItem"
                          ),
                          value: 0,
                        },
                        {
                          text: _vm.$t("Inventory.addDetail_Header_Tab_Recipe"),
                          value: 1,
                        },
                      ],
                    },
                    model: {
                      value: _vm.type,
                      callback: function ($$v) {
                        _vm.type = $$v
                      },
                      expression: "type",
                    },
                  })
                : _vm._e(),
            ],
            1
          ),
          _vm.type === 0
            ? [
                _c("div", { staticClass: "Form-item required" }, [
                  _c("label", { staticClass: "Form-item-label" }, [
                    _vm._v(
                      _vm._s(
                        _vm.$t(
                          "ListsInventory.detail_Popup_FormField_StockItemName"
                        )
                      )
                    ),
                  ]),
                  _c(
                    "div",
                    { staticClass: "control" },
                    [
                      _c(
                        "custom-search",
                        {
                          directives: [
                            {
                              name: "validate",
                              rawName: "v-validate",
                              value: "required",
                              expression: "'required'",
                            },
                          ],
                          ref: "firstInput",
                          attrs: {
                            "component-name": "input-stock-item",
                            name: "stockItem",
                            "data-vv-as": _vm.$t(
                              "ListsInventory.detail_Popup_FormField_StockItemName"
                            ),
                            inputClass: {
                              "is-danger":
                                _vm.veeErrors.has("stockItem") &&
                                _vm.selectOptions.length === 0,
                            },
                            "on-search": _vm.getOptions,
                            label: "name",
                            loading: _vm.isSearchStockItem,
                            options: _vm.selectOptions,
                            placeholder: _vm.$t(
                              "ListsInventory.detail_Popup_FormField_StockItemName_Placeholder"
                            ),
                          },
                          on: { input: _vm.onChangeStockItem },
                          model: {
                            value: _vm.selectedStockItem,
                            callback: function ($$v) {
                              _vm.selectedStockItem = $$v
                            },
                            expression: "selectedStockItem",
                          },
                        },
                        [
                          _c("template", { slot: "no-options" }, [
                            _vm._v(
                              _vm._s(
                                _vm.$t(
                                  "ListsInventory.detail_Popup_FormField_StockItemName_NoResults"
                                )
                              )
                            ),
                          ]),
                        ],
                        2
                      ),
                    ],
                    1
                  ),
                ]),
                _c(
                  "p",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value:
                          _vm.veeErrors.has("stockItem") &&
                          _vm.selectOptions.length === 0,
                        expression:
                          "veeErrors.has('stockItem') && selectOptions.length === 0",
                      },
                    ],
                    staticClass: "Form-item-help is-danger",
                  },
                  [_vm._v(_vm._s(_vm.veeErrors.first("stockItem")))]
                ),
                _vm.selectedStockItem
                  ? _c("div", { staticClass: "countingTable" }, [
                      _c("div", { staticClass: "countingHeader" }, [
                        _c("span", [
                          _vm._v(
                            _vm._s(
                              _vm.$t(
                                "Inventories.popupCountingEdit_PreviousCount"
                              )
                            )
                          ),
                        ]),
                        _c("span", [
                          _vm._v(
                            _vm._s(_vm.$t("Inventories.popupCountingEdit_Unit"))
                          ),
                        ]),
                        _c("span", { staticClass: "align-right" }, [
                          _vm._v(
                            _vm._s(
                              _vm.$t("Inventories.popupCountingEdit_NewCount")
                            )
                          ),
                        ]),
                      ]),
                      _c(
                        "form",
                        {
                          staticClass: "countingItems",
                          attrs: {
                            id: "form-edit-inventory",
                            "data-vv-scope": "actualUnitQuantity",
                          },
                          on: {
                            submit: function ($event) {
                              $event.preventDefault()
                              return _vm.submitForm.apply(null, arguments)
                            },
                          },
                        },
                        _vm._l(
                          _vm.countingList,
                          function (countingInfo, index) {
                            return _c("div", { staticClass: "countingItem" }, [
                              _c("span", [
                                _vm._v(_vm._s(countingInfo.unitQuantity)),
                              ]),
                              _c("span", [
                                _vm._v(_vm._s(countingInfo.unitName)),
                              ]),
                              _c("span", { staticClass: "align-right" }, [
                                _c("input", {
                                  directives: [
                                    {
                                      name: "validate",
                                      rawName: "v-validate",
                                      value: "min:0",
                                      expression: "'min:0'",
                                    },
                                    {
                                      name: "model",
                                      rawName: "v-model.number",
                                      value: countingInfo["actualUnitQuantity"],
                                      expression:
                                        "countingInfo['actualUnitQuantity']",
                                      modifiers: { number: true },
                                    },
                                  ],
                                  staticClass:
                                    "txt quantity ff-mono no-spinners countingInput",
                                  class: {
                                    "is-danger":
                                      countingInfo["actualUnitQuantity"] < 0,
                                  },
                                  attrs: {
                                    id: `actual-unit-quantity-${index}`,
                                    name: `actualUnitQuantity${index}`,
                                    "data-vv-as": _vm.$t(
                                      "Inventories.popupCountingEdit_NewCount"
                                    ),
                                    type: "number",
                                    step: "any",
                                    autocomplete: "off",
                                  },
                                  domProps: {
                                    value: countingInfo["actualUnitQuantity"],
                                  },
                                  on: {
                                    input: function ($event) {
                                      if ($event.target.composing) return
                                      _vm.$set(
                                        countingInfo,
                                        "actualUnitQuantity",
                                        _vm._n($event.target.value)
                                      )
                                    },
                                    blur: function ($event) {
                                      return _vm.$forceUpdate()
                                    },
                                  },
                                }),
                              ]),
                            ])
                          }
                        ),
                        0
                      ),
                    ])
                  : _vm._e(),
                _vm.selectedStockItem
                  ? _c("div", { staticClass: "countingTBTable" }, [
                      _c("div", { staticClass: "countingHeader" }, [
                        _c("span", [
                          _vm._v(
                            _vm._s(
                              _vm.$t(
                                "Inventories.popupCountingEdit_PreviousBaseQuantity"
                              )
                            )
                          ),
                        ]),
                        _c("span", { staticClass: "align-right" }, [
                          _vm._v(
                            _vm._s(
                              _vm.$t(
                                "Inventories.popupCountingEdit_NewBaseQuantity"
                              )
                            )
                          ),
                        ]),
                      ]),
                      _c("div", { staticClass: "countingItems" }, [
                        _c("div", { staticClass: "countingItem" }, [
                          _c("span", [_vm._v(_vm._s(_vm.beforeBaseQuantity))]),
                          _c("span", { staticClass: "align-right" }, [
                            _vm._v(_vm._s(_vm.actualBaseQuantity)),
                          ]),
                        ]),
                      ]),
                    ])
                  : _vm._e(),
              ]
            : _c(
                "form",
                {
                  staticClass: "Form",
                  attrs: { id: "form-edit-inventory" },
                  on: {
                    submit: function ($event) {
                      $event.preventDefault()
                      return _vm.onSubmitRecipe.apply(null, arguments)
                    },
                  },
                },
                [
                  _c("div", { staticClass: "Form-item required" }, [
                    _c("label", { staticClass: "Form-item-label" }, [
                      _vm._v(
                        _vm._s(
                          _vm.$t("Inventory.addDetail_Header_Recipe_Label")
                        )
                      ),
                    ]),
                    _c(
                      "div",
                      { staticClass: "control" },
                      [
                        _c(
                          "custom-search",
                          {
                            directives: [
                              {
                                name: "validate",
                                rawName: "v-validate",
                                value: "required",
                                expression: "'required'",
                              },
                            ],
                            attrs: {
                              "component-name":
                                "select-recipe-add-recipe-popup",
                              name: "name",
                              "data-vv-scope": "recipe",
                              "data-vv-as": _vm.$t(
                                "Inventory.addDetail_Header_Recipe_Label"
                              ),
                              inputClass: {
                                "is-danger": _vm.veeErrors.has("recipe.name"),
                              },
                              "on-search": _vm.searchRecipes,
                              options: _vm.searchedRecipes,
                              label: "name",
                              loading: _vm.$wait.is("searchRecipeItemByName"),
                              placeholder: _vm.$t(
                                "Inventory.addDetail_Header_Recipe_Placeholder"
                              ),
                            },
                            model: {
                              value: _vm.form.recipe,
                              callback: function ($$v) {
                                _vm.$set(_vm.form, "recipe", $$v)
                              },
                              expression: "form.recipe",
                            },
                          },
                          [
                            _c("template", { slot: "no-options" }, [
                              _vm._v(
                                _vm._s(
                                  _vm.$t(
                                    "Inventory.addDetail_Header_Recipe_NoData"
                                  )
                                )
                              ),
                            ]),
                          ],
                          2
                        ),
                      ],
                      1
                    ),
                  ]),
                  _vm.form.recipe
                    ? _c("div", { staticClass: "countingTable" }, [
                        _c("div", { staticClass: "countingHeader" }, [
                          _c("span", [
                            _vm._v(
                              _vm._s(
                                _vm.$t(
                                  "Inventories.popupCountingEdit_PreviousCount"
                                )
                              )
                            ),
                          ]),
                          _c("span", [
                            _vm._v(
                              _vm._s(
                                _vm.$t("Inventories.popupCountingEdit_Unit")
                              )
                            ),
                          ]),
                          _c("span", { staticClass: "align-right" }, [
                            _vm._v(
                              _vm._s(
                                _vm.$t("Inventories.popupCountingEdit_NewCount")
                              )
                            ),
                          ]),
                        ]),
                        _c("div", { staticClass: "countingItems" }, [
                          _c("div", { staticClass: "countingItem" }, [
                            _c("span", [
                              _vm._v(_vm._s(_vm.form.recipe.quantity)),
                            ]),
                            _c("span", [
                              _vm._v(
                                _vm._s(
                                  _vm.form.recipe.portionQuantity +
                                    " " +
                                    _vm.form.recipe.portionUnitName
                                )
                              ),
                            ]),
                            _c("span", { staticClass: "align-right" }, [
                              _c("input", {
                                directives: [
                                  {
                                    name: "validate",
                                    rawName: "v-validate",
                                    value: "required|min:0",
                                    expression: "'required|min:0'",
                                  },
                                  {
                                    name: "model",
                                    rawName: "v-model.number",
                                    value: _vm.form.actualQuantity,
                                    expression: "form.actualQuantity",
                                    modifiers: { number: true },
                                  },
                                ],
                                staticClass:
                                  "txt quantity ff-mono no-spinners countingInput",
                                class: {
                                  "is-danger": _vm.veeErrors.has(
                                    "recipe.actualUnitQuantity"
                                  ),
                                },
                                attrs: {
                                  id: "actual-unit-quantity-recipe",
                                  name: "actualUnitQuantity",
                                  "data-vv-scope": "recipe",
                                  "data-vv-as": _vm.$t(
                                    "Inventories.popupCountingEdit_NewCount"
                                  ),
                                  type: "number",
                                  step: "any",
                                  autocomplete: "off",
                                },
                                domProps: { value: _vm.form.actualQuantity },
                                on: {
                                  input: function ($event) {
                                    if ($event.target.composing) return
                                    _vm.$set(
                                      _vm.form,
                                      "actualQuantity",
                                      _vm._n($event.target.value)
                                    )
                                  },
                                  blur: function ($event) {
                                    return _vm.$forceUpdate()
                                  },
                                },
                              }),
                            ]),
                          ]),
                        ]),
                      ])
                    : _vm._e(),
                ]
              ),
        ],
        2
      ),
      _c(
        "template",
        { slot: "footer" },
        [
          _c(
            "Button",
            {
              attrs: {
                size: "medium",
                variant: "full",
                primary: "",
                id: "btn-inventory-popup-submit",
                type: "submit",
                form: "form-edit-inventory",
                disabled: _vm.$wait.is(["onSubmitRecipe"]),
              },
            },
            [
              _c("span", [
                _vm._v(
                  _vm._s(_vm.$t("Inventories.popupCountingEdit_EditButton"))
                ),
              ]),
            ]
          ),
        ],
        1
      ),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }