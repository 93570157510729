<template lang="pug">
.massec-import(v-if="massecIsActive")
    import-excel(
      :url="`Inventory/import/massec?InventoryHeaderId=${inventoryInfo.id}`"
      @success="importConfirmAction",
      iconName="icon-massec"
      :isTypeMassec="true"
      id="inventory-import-massec-excel"
      componentName="inventory"
    )
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
//
import ImportExcel from '@/view/global/import-excel'

export default {
  name: 'ImportMassec',
  props: {
    inventoryInfo: {
      type: Object,
      default: () => { return {} }
    },
    page: {
      type: String,
      required: true
    }
  },
  components: {
    ImportExcel
  },

  computed: {

    ...mapGetters('Integration', ['massecIsActive']),

    succesActionRouterName () {
      switch (this.page) {
        case 'inventory':
          return 'InventoryMassecImport'
        case 'spot-inventory':
          return 'InventorySpotMassecImport'
      }
    }
  },

  methods: {

    ...mapActions('Integration', ['checkIntegrationMassecStatus']),

    importConfirmAction (result) {
      this.$router.push({
        name: this.succesActionRouterName,
        params: {
          countList: [...result.data.inventoryCountList]
        }
      })
    }
  },

  mounted () {
    this.checkIntegrationMassecStatus()
  }
}
</script>

<style lang="scss">

  .massec-import {
    .import-excel-button {
      width: 69px !important;
      height: 54px !important;
      align-items: center;
      border: 1px solid $color-red;

      .icon-massec {
        width: 46px;
        height: 29px;
      }
    }
  }
</style>
