<template lang="pug">
  Popup(
        :status="status"
        @buttonClosePopup="close"
    )
        template(slot="title") {{ $t('Inventories.popupCountingEdit_Title') }}
        template(slot="detail")
            .Popup-info {{ inventoryTitle }}

        template(slot="content")
          .selectType
            SelectedButtons(v-if="inventory.type !== 2" v-model="type", :data="[{text: $t('Inventory.addDetail_Header_Tab_StockItem'), value: 0 }, { text: $t('Inventory.addDetail_Header_Tab_Recipe'), value: 1 }]")
          template(v-if="type === 0")
            .Form-item.required
                label.Form-item-label {{ $t('ListsInventory.detail_Popup_FormField_StockItemName') }}
                .control
                    custom-search(
                        component-name="input-stock-item"
                        ref="firstInput"
                        name="stockItem"
                        v-validate="'required'"
                        :data-vv-as="$t('ListsInventory.detail_Popup_FormField_StockItemName')"
                        :inputClass="{ 'is-danger': veeErrors.has('stockItem') && selectOptions.length === 0 }"
                        :on-search="getOptions"
                        label="name"
                        :loading="isSearchStockItem"
                        :options="selectOptions"
                        :placeholder="$t('ListsInventory.detail_Popup_FormField_StockItemName_Placeholder')"
                        @input="onChangeStockItem"
                        v-model="selectedStockItem"
                    )
                        template(slot="no-options") {{ $t('ListsInventory.detail_Popup_FormField_StockItemName_NoResults') }}

            p.Form-item-help.is-danger(
                v-show="veeErrors.has('stockItem') && selectOptions.length === 0"
            ) {{ veeErrors.first('stockItem') }}

            .countingTable(
                v-if="selectedStockItem"
            )
                .countingHeader
                    span {{ $t('Inventories.popupCountingEdit_PreviousCount') }}
                    span {{ $t('Inventories.popupCountingEdit_Unit') }}
                    span.align-right {{ $t('Inventories.popupCountingEdit_NewCount') }}
                form.countingItems(
                  id="form-edit-inventory"
                  data-vv-scope="actualUnitQuantity"
                  @submit.prevent="submitForm"
                )
                  .countingItem(
                      v-for="countingInfo, index in countingList"
                  )
                      span {{ countingInfo.unitQuantity }}
                      span {{ countingInfo.unitName }}
                      span.align-right
                          input.txt.quantity.ff-mono.no-spinners.countingInput(
                              :id="`actual-unit-quantity-${index}`"
                              :name="`actualUnitQuantity${index}`"
                              :data-vv-as="$t('Inventories.popupCountingEdit_NewCount')",
                              :class="{ 'is-danger': countingInfo['actualUnitQuantity'] < 0 }"
                              v-validate="'min:0'"
                              type="number"
                              step="any"
                              autocomplete="off"
                              v-model.number="countingInfo['actualUnitQuantity']"
                          )
            .countingTBTable(
                v-if="selectedStockItem"
            )
                .countingHeader
                    span {{ $t('Inventories.popupCountingEdit_PreviousBaseQuantity') }}
                    span.align-right {{ $t('Inventories.popupCountingEdit_NewBaseQuantity') }}
                .countingItems
                    .countingItem
                        span {{ beforeBaseQuantity }}
                        span.align-right {{ actualBaseQuantity }}
          form.Form(
            id="form-edit-inventory"
            @submit.prevent="onSubmitRecipe"
            v-else)

            .Form-item.required
              label.Form-item-label {{ $t('Inventory.addDetail_Header_Recipe_Label') }}
              .control
                custom-search(
                  component-name="select-recipe-add-recipe-popup"
                  name="name"
                  v-model="form.recipe"
                  data-vv-scope="recipe"
                  :data-vv-as="$t('Inventory.addDetail_Header_Recipe_Label')"
                  v-validate="'required'"
                  :inputClass="{ 'is-danger': veeErrors.has('recipe.name') }"
                  :on-search="searchRecipes"
                  :options="searchedRecipes"
                  label="name"
                  :loading="$wait.is('searchRecipeItemByName')"
                  :placeholder="$t('Inventory.addDetail_Header_Recipe_Placeholder')")
                    template(slot="no-options") {{ $t('Inventory.addDetail_Header_Recipe_NoData') }}
            .countingTable(v-if="form.recipe")
              .countingHeader
                  span {{ $t('Inventories.popupCountingEdit_PreviousCount') }}
                  span {{ $t('Inventories.popupCountingEdit_Unit') }}
                  span.align-right {{ $t('Inventories.popupCountingEdit_NewCount') }}
              .countingItems
                .countingItem
                    span {{ form.recipe.quantity }}
                    span {{ form.recipe.portionQuantity + ' ' + form.recipe.portionUnitName }}
                    span.align-right
                        input.txt.quantity.ff-mono.no-spinners.countingInput(
                            id="actual-unit-quantity-recipe"
                            name="actualUnitQuantity"
                            data-vv-scope="recipe"
                            :data-vv-as="$t('Inventories.popupCountingEdit_NewCount')",
                            :class="{ 'is-danger': veeErrors.has('recipe.actualUnitQuantity') }"
                            v-validate="'required|min:0'"
                            type="number"
                            step="any"
                            autocomplete="off"
                            v-model.number="form.actualQuantity"
                        )
        template(slot="footer")
            Button(
              size="medium"
              variant="full"
              primary
              id="btn-inventory-popup-submit"
              type="submit"
              form="form-edit-inventory"
              :disabled="$wait.is(['onSubmitRecipe'])"
            )
                span {{ $t('Inventories.popupCountingEdit_EditButton') }}
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import Popup from './index'
import moment from 'moment'

export default {
  name: 'InventoryEditPopup',
  data () {
    return {
      type: 0,
      selectOptions: [],
      selectedStockItem: null,
      countingList: [],
      searchedRecipes: [],
      form: {
        stockItem: null,
        recipe: null,
        actualQuantity: null,
        counting: {}
      }
    }
  },
  props: {
    inventory: {
      type: Object,
      default: {
        storeName: null,
        inventoryDate: null
      }
    },
    status: {
      type: Boolean,
      default: false
    }
  },
  components: {
    Popup,
  },

  computed: {
    ...mapGetters('Inventories', ['inventoryRecipeCountList']),
    inventoryTitle () {
      let inventoryDate = this.inventory ? moment(this.inventory.inventoryDate).format('DD.MM.YYYY') : ''
      let store = this.inventory ? this.inventory.storeName : ''
      return `${inventoryDate} - ${store}`
    },
    beforeBaseQuantity () {
      let countingList = this.countingList
      let beforeBaseQuantity = 0
      countingList.forEach(cl => {
        beforeBaseQuantity += cl.baseQuantity * (cl.unitQuantity || 0)
      })
      return beforeBaseQuantity
    },
    actualBaseQuantity () {
      let countingList = this.countingList
      let actualBaseQuantity = 0
      countingList.forEach(cl => {
        actualBaseQuantity +=
          cl.baseQuantity * (cl.actualUnitQuantity || 0)
      })
      return actualBaseQuantity
    },
    isSearchStockItem () {
      this.$wait.is('searchStockItem')
    }
  },
  methods: {
    ...mapActions('Stock', ['searchStockItem']),
    ...mapActions('Recipes', ['findRecipeInInventory']),
    ...mapActions('Inventories', ['getInventoryCountingByStockItemId', 'upsertClosedInventoryRecipeCount']),
    ...mapActions('Notify', [
      'notifyShow'
    ]),
    ...mapActions('Recipes', [
      'searchRecipeItemByName',
      'get_RECIPE_DETAIL'
    ]),

    getOptions (search) {
      this.searchStockItem({ text: search, InventoryHeaderId: this.inventory.id, types: [1, 2, 3] })
        .then(res => {
          if (res.status === 200) {
            this.selectOptions = res.data.items
          }
        })
    },

    searchRecipes (search) {
      this.findRecipeInInventory({ name: search, inventoryHeaderId: this.inventory.id })
        .then(res => {
          this.searchedRecipes = res.data.recipeHeaderList
        })
    },
    onSubmitRecipe () {
      this.$wait.start('onSubmitRecipe')
      this.$validator.validateAll('recipe')
        .then(res => {
          if (!res) {
            this.$wait.end('onSubmitRecipe')
            return
          }
          const payload = {
            inventoryHeaderId: this.inventory.id,
            recipeHeaderId: this.form.recipe.id,
            actualQuantity: this.form.actualQuantity ? this.form.actualQuantity : null
          }
          this.upsertClosedInventoryRecipeCount(payload)
            .then(() => {
              this.$wait.end('onSubmitRecipe')
              this.close()
            })
        })
    },
    // selectRecipe(value) {
    //   if (!value) return
    //   this.get_RECIPE_DETAIL({recipeHeaderId: value.id})
    //     .then(res => {
    //       if (res.status === 200) {
    //         this.form.recipe = res.data.recipeHeader
    //       }
    //     })
    // },
    close () {
      this.$emit('closePopup')
      this.selectedStockItem = null
      this.type = 0
      this.countingList = []
      this.form.actualQuantity = null
      this.form.recipe = null
      this.searchedRecipes = []
    },
    submitForm () {
      let isValid = !this.countingList.some(cl => cl.actualUnitQuantity < 0)
      if (!isValid) return
      let form = {
        inventoryHeaderId: this.inventory.id,
        stockItemId: this.selectedStockItem.id,
        unitCountList: this.countingList.map(cl => ({
          unitId: cl.unitId,
          actualQuantity: cl.actualUnitQuantity ? cl.actualUnitQuantity : null
        }))
      }
      this.$emit('submit', form)
      this.selectedStockItem = null
      this.countingList = []
      const message = this.$t('Inventories.popup_Add_Success_Message')
      this.notifyShow({ message })
    },
    onChangeStockItem (value) {
      this.selectedStockItem = value
      if (value) {
        this.getInventoryCountingByStockItemId({
          inventoryHeaderId: this.inventory.id,
          stockItemId: value.id
        }).then(res => {
          this.countingList = res.data.inventoryCountList.map(cl => {
            if (!cl.unitQuantity) {
              cl['unitQuantity'] = 0
            }
            if (!cl.actualUnitQuantity) {
              cl['actualUnitQuantity'] = null
            }
            return cl
          })
        })
      }
    }
  }
}
</script>

<style lang="scss" scoped>

.Popup-info {
  font-weight: 600;
  margin-top: 10px;
}

.countingTable,
.countingTBTable {
  display: table;
  border-collapse: collapse;
  margin: 20px 0;
  width: 100%;

  .countingItems {
    display: table-row-group;
    border-bottom: 1px solid $color-gray;
    .countingItem {
      span {
        vertical-align: middle;
        padding: 10px 0;
      }

      &:last-child {
        span {
          padding-bottom: 20px;
        }
      }
    }
  }

  .countingHeader,
  .countingItem {
    display: table-row;
    span {
      display: table-cell;

      &.align-right {
        text-align: right;
      }

      .countingInput {
        min-height: 36px;
        max-width: 80px;
        margin-left: auto;
      }
    }
  }

  .countingHeader {
    span {
      color: $color-success;
    }
  }
}

.countingTBTable {
  .countingItems {
    border-bottom: none;
  }
}
</style>
