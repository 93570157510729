import Inventories from '../inventories'
import Inventory from '../inventory'
import InventoryRecipe from '../inventory-recipe'
import InventoryList from '../inventory-lists'
import InventorySpotList from '../inventory-lists-spot'
import InventoryRemove from '../popup/remove'
import InventoryEdit from '../popup/edit'
import InventoryClose from '../popup/close'
import InventoryAddStockItem from '../popup/add-stock'
import InventoryShelf from '../inventory-shelf'
import InventoryExpireDate from '../inventory-expire-date'
import Massec from '../popup/imported-inventory-detail'
import InventoryCreateBatch from '../popup/add-batch'
import InventoryExpireDateSpotList from '../inventory-expire-date-spot-list'
import InventorySpotListCreateBatch from '../popup/add-spot-list-batch'


export default [
  {
    path: '/inventories',
    name: 'Inventories',
    component: Inventories,
    meta: {
      main_menu: true,
      slug: 'inventory',
      category: 'Stock',
      permissionKey: 'InventoryMenu',
      page: 'inventory'
    },
    children: [
      {
        path: ':id/remove',
        name: 'InventoryRemove',
        component: InventoryRemove
      },
      {
        path: ':id/edit',
        name: 'InventoryEdit',
        component: InventoryEdit
      },
      {
        path: ':id/:status/:storeName/:inventoryDate/close',
        name: 'InventoryClose',
        component: InventoryClose
      }
    ]
  },
  {
    path: '/inventories/detail/:id/:status/:storeName/:inventoryType/:inventoryDate',
    name: 'Inventory',
    component: Inventory,
    meta: {
      slug: 'inventory',
      permissionKey: 'InventoryMenu',
      page: 'inventory'
    },
    children: [
      {
        path: '/inventories-close/close-inventory/:id/:status/:storeName/:inventoryType/:inventoryDate',
        name: 'InventoryDetailClose',
        component: InventoryClose
      },
      {
        name: 'InventoryMassecImport',
        path: 'import/massec',
        component: Massec
      },
      {
        path: '/inventories-add-stock/add-stock-inventory/:id/:status/:storeName/:inventoryType/:inventoryDate',
        name: 'InventoryAddStockItem',
        component: InventoryAddStockItem
      }
    ]
  },
  {
    path: '/inventories/recipe/detail/:id/:status/:storeName/:inventoryType/:inventoryDate',
    name: 'InventoryRecipe',
    component: InventoryRecipe,
    meta: {
      slug: 'inventory',
      page: 'inventory',
      permissionKey: 'InventoryMenu'
    },
    children: [
      {
        path: '/inventories-close/recipe/close-inventory/:id/:status/:storeName/:inventoryType/:inventoryDate',
        name: 'InventoryRecipeDetailClose',
        component: InventoryClose
      },
      {
        path: '/inventories-add-stock/recipe/:id/:status/:storeName/:inventoryType/:inventoryDate',
        name: 'InventoryAddRecipe',
        component: InventoryAddStockItem
      }
    ]
  },
  {
    path: '/inventories/list/:id/:status/:storeName/:inventoryType/:inventoryDate',
    name: 'Inventorylists',
    component: InventoryList,
    meta: {
      permissionKey: 'InventoryMenu',
      page: 'inventory'
    },
    children: [
      {
        path: '/inventories/list/close-inventory/:id/:status/:storeName/:inventoryType/:inventoryDate',
        name: 'InventoryListDetailClose',
        component: InventoryClose
      }
    ]
  },
  {
    path: '/inventories/spot-list/:id/:status/:storeName/:inventoryType/:inventoryDate',
    name: 'InventorySpotList',
    component: InventorySpotList,
    meta: {
      page: 'inventory',
      permissionKey: 'InventoryMenu'
    },
    children: [
      {
        path: '/inventories/spot-list/close-inventory/:id/:status/:storeName/:inventoryType/:inventoryDate',
        name: 'InventorySpotListDetailClose',
        component: InventoryClose
      },
      {
        name: 'InventorySpotMassecImport',
        path: 'import/spot-massec',
        component: Massec
      }
    ]
  },
  {
    path: '/inventories/shelf/detail/:id/:status/:storeName/:inventoryType/:inventoryDate',
    name: 'InventoryShelf',
    component: InventoryShelf,
    meta: {
      page: 'inventory',
      permissionKey: 'InventoryMenu'
    }
  },
  {
    path: '/inventories/detail/expire-date/:id/:status/:storeName/:inventoryType/:inventoryDate',
    name: 'InventoryExpireDate',
    component: InventoryExpireDate,
    meta: {
      page: 'inventory',
      permissionKey: 'InventoryMenu'
    },
    children: [
      {
        path: '/inventories/detail/expire-date/:id/:status/:storeName/:inventoryType/:inventoryDate/:storeId/create-batch',
        name: 'InventoryCreateBatch',
        component: InventoryCreateBatch
      }
    ]
  },
  {
    path: '/inventories/spot-list/expire-date/:id/:status/:storeName/:inventoryType/:inventoryDate',
    name: 'InventoryExpireDateSpotList',
    component: InventoryExpireDateSpotList,
    meta: {
      page: 'inventory',
      permissionKey: 'InventoryMenu'
    },
    children: [
      {
        path: '/inventories/spot-list/expire-date/:id/:status/:storeName/:inventoryType/:inventoryDate/:storeId/create-batch',
        name: 'InventorySpotListCreateBatch',
        component: InventorySpotListCreateBatch
      }
    ]
  }
]
