<template lang="pug">
transition(name="slide")
    .Popup(v-if="status")
      .Popup-overlay(@click="close")
      .Popup-body

        .Popup-header
          .content
            h3.Popup-header-title
              slot(name="title")
            slot(name="detail")
          Button.Popup-header-close(
          variant="icon"
          type="button"
          id="btn-inventory-popup-close"
          iconName="icon-popup-close"
          iconClass="icon-popup-close"
          @click="close")

        .Popup-content
          slot(name="content")
        .Popup-footer
          slot(name="footer")
</template>

<script>
export default {
  name: 'Popup',

  props: ['status'],

  data () {
    return {
      statuss: true
    }
  },

  methods: {
    close () {
      this.$emit('buttonClosePopup')
    }
  }
}
</script>

<style lang="scss" scoped>

  // .fade-enter-active,
  // .fade-leave-active {
  //   transition: 0.3s;
  //   opacity: 0;
  // }

  // .fade-enter-to {
  //   opacity: 1;
  // }

  // .slide-enter-active,
  // .slide-leave-active {
  //   transition: 0.3s;
  //   opacity: 0;
  //   transform: translateX(100%);
  // }

  // .slide-enter-to {
  //   opacity: 1;
  //   transform: translateX(0);
  // }
</style>
