export function getFields($t) {
  return [
    {
      name: 'stockItemName',
      is_visible: true,
      get short_title() {
        return $t('Despatches.detail_TableColumn_StockItemName')
      },
      get title() {
        return $t('Despatches.detail_TableColumn_StockItemName')
      }
    },
    {
      name: 'baseUnitCode',
      is_visible: true,
      get short_title() {
        return $t('OnHand.tableColumn_BaseUnitCode')
      },
      get title() {
        return $t('OnHand.tableColumn_BaseUnitCode')
      }
    },
    {
      name: 'expectedQuantity',
      is_visible: true,
      type: 'currency',
      get short_title() {
        return $t('Inventory.detailList_TableColumn_ExpectedQuantity')
      },
      get title() {
        return $t('Inventory.detailList_TableColumn_ExpectedQuantity')
      }
    },
    {
      name: 'totalBatchQuantity',
      is_visible: true,
      type: 'currency',
      get short_title() {
        return $t('Inventory.Table_Batch_TotalBatchQuantity')
      },
      get title() {
        return $t('Inventory.Table_Batch_TotalBatchQuantity')
      }
    },
    {
      name: 'totalCount',
      is_visible: true,
      type: 'currency',
      get short_title() {
        return $t('Inventory.totalCount')
      },
      get title() {
        return $t('Inventory.totalCount')
      }
    }
  ]
}
export function getDetailFields($t) {
	return [
		{
			name: 'batchQuantity',
      type: 'currency',
      is_visible: true,
			get short_title() {
				return $t('Inventory.batchQuantity')
			},
			get title() {
				return $t('Inventory.batchQuantity')
			}
		},
    {
			name: 'count',
      type: 'portal',
      is_visible: true,
			get short_title() {
				return $t('Inventory.count')
			},
			get title() {
				return $t('Inventory.count')
			}
		},
    {
			name: 'expirationDate',
      type: 'date',
      is_visible: true,
			get short_title() {
				return $t('Despatches.expirationDate')
			},
			get title() {
				return $t('Despatches.expirationDate')
			}
		},
    {
			name: 'lotNumber',
      is_visible: true,
			get short_title() {
				return $t('Despatches.lotNumber')
			},
			get title() {
				return $t('Despatches.lotNumber')
			}
		},
    {
			name: 'shelfStorageLocationListHeaderName',
      is_visible: true,
			get short_title() {
				return $t('Transfer.shelfStorageLocationListHeaderName')
			},
			get title() {
				return $t('Transfer.shelfStorageLocationListHeaderName')
			}
		}
	]
}

