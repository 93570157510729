<template lang="pug">
blured-background
    .imported-inventory-counts
      .imported-inventory-counts-header
        Icon.imported-inventory-counts-header-icon(name="icon-global-refresh-data")
        label.imported-inventory-counts-header-title {{ $t('Massec.Import_Header_Title') }}
        label.imported-inventory-counts-header-desc {{ $t('Massec.Import_Header_Description') }}
      .imported-inventory-counts-table
        TableView(
          :componentName="'MassecTable'"
          :fields="fields"
          :data="countList"
          :tableName="$t('Stocks.tableName')"
          :read-only="true"
          )
      .imported-inventory-counts-buttons
        Button.imported-inventory-counts-button.yes(
          primary
          size="medium"
          @click="update"
          :isLoading="isLoadingUpdate"
          :disabled="isLoadingUpdate"
          ) {{ $t('Massec.Import_Button_Yes') }}
        Button.imported-inventory-counts-button.no(@click="close") {{ $t('Massec.Import_Button_No') }}
</template>

<script>
import bluredBackground from '@/view/global/full-blur-background'
import { mapActions } from 'vuex'
import getFields from '../massec-import-field'

export default {
  name: 'ImportedMassec',

  data () {
    return {
      fields: getFields(this.$t)
    }
  },

  components: {
    bluredBackground
  },

  mounted () {
    if (!this.countList || this.countList.length === 0) this.$nextTick(() => this.close())
  },

  computed: {

    isLoadingUpdate () {
      return this.$wait.is(['update_IMPORT_INVENTORY_MASSEC'])
    },

    countList () {
      return this.$route.params.countList
    },

    inventoryHeaderId () {
      return this.$route.params
    }
  },

  methods: {
    ...mapActions('Inventories', [
      'update_IMPORT_INVENTORY_MASSEC'
    ]),
    close () {
      this.$router.push({
        name: this.$route.matched[0].name
      })
    },

    update () {
      const payload = {
        inventoryHeaderId: this.$route.params.id,
        inventoryCountList: this.countList.map(count => {
          return {
            stockItemId: count.stockItemId,
            unitId: count.unitId,
            actualQuantity: count.actualQuantity
          }
        })
      }
      this.update_IMPORT_INVENTORY_MASSEC(payload)
        .then(async () => {
          this.close()
          await this.$emit('successImportAction')
        })
    }
  }
}
</script>

<style lang="scss" scoped>

.imported-inventory-counts {
  max-height: 75%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  &-table {
    position: relative;
    overflow-y: scroll;
    margin-bottom: 50px;
    max-height: 400px;
    background-color: $color-white;
    box-shadow: -6px 3px 22px 0 rgba(0, 0, 0, 0.08);
  }

  &-header {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-bottom: 41px;

    &-icon {
      color: $color-warning;
      margin-bottom: 11px;
      width: 60px;
      height: 60px;
    }

    &-title {
      margin-bottom: 8px;
      font-family: $font-family;
      font-size: $font-size-Hbigger;
      font-weight: $font-weight-bold;
      color: $color-dark;
    }

    &-desc {
      font-family: $font-family;
      font-size: $font-size-big;
      color: $color-light;
    }
  }

  &-buttons {

    display: flex;

    .imported-inventory-counts-button {
      width: 281px;
      margin-left: 10px;
      font-size: $font-size-normal;
      transition: all 0.2s ease;

      // button no
      &.no {
        color: $color-light;
        border: 1px solid $color-light;

        &:hover {
          color: darken($color-light, 10%)
        }
      }

      // button yes
      &.yes {

        &:hover {
          color: darken($color-success, 10%)
        }
      }
    }
  }
}
</style>
