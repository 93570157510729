<template lang="pug">
div
    Alert(
      v-if="InventoryInfo.inventoryClosingMethod !== 2 || showFinalAlert"
      :title="$t('Inventory.confirm_CloseInventory')"
      :description="$t('Inventory.confirm_CloseInventory_Message')"
      :okButton="$t('Inventory.confirm_CloseInventory_Close')"
      icon="icon-global-notify"
      loading='itemCloseInventory'
      @delegateOnAlertOk="itemClose"
      @delegateOnAlertCancel="close"
    )
    Alert(
      v-if="InventoryInfo.inventoryClosingMethod === 2 && showFirstAlert"
      :title="$t('Inventory.confirm_uncountedDelete')"
      :description="$t('Inventory.confirm_uncountedMessage')"
      :okButton="$t('Inventory.confirm_CloseInventory_Close')"
      icon="icon-global-notify"
      loading='itemCloseInventory'
      @delegateOnAlertOk="confirmFirstAlert"
      @delegateOnAlertCancel="close"
    )

</template>

<script>
import { mapActions } from 'vuex'
import { vueWaitLoader } from '@/utils/baseOperations'
import getApiErrorMessage from '@/utils/get-api-error-message'

export default {
  name: 'InventoryClose',

  computed: {
    InventoryInfo () {
      return {
        ...this.$route.params,
        status: parseInt(this.$route.params.status)
      }
    }
  },

  data () {
    return {
      showFinalAlert: false,
      showFirstAlert: true
    }
  },
  methods: {
    ...mapActions('Notify', [
      'notifyShow'
    ]),

    ...mapActions('Inventories', [
      'close_INVENTORY'
    ]),

    close (routeName = this.$route.matched[0].name, params = this.InventoryInfo) {
      this.$router.push({
        name: routeName,
        params
      })
    },

    itemClose: vueWaitLoader(async function () {
      await this.close_INVENTORY(this.InventoryInfo.id).then(res => {
        if (res.status === 200) {
          const message = this.$t('Inventory.notification_InventoryClosed')
          this.notifyShow({ message })
          const routeName = this.$route.matched[0].name
          const params = {
            id: this.InventoryInfo.id,
            storeName: this.InventoryInfo.storeName,
            inventoryDate: this.InventoryInfo.inventoryDate,
            status: 2
          }
          const route = routeName === 'Inventorylists' || routeName === 'InventorySpotList' ? 'Inventory' : routeName
          if (routeName !== 'InventorySpotList') this.$emit('getList')
          this.close(route, params)
        } else {
          const message = getApiErrorMessage(this, res)
          this.notifyShow({ message })
        }
      })
    }, 'itemCloseInventory'),

    confirmFirstAlert () {
      this.showFirstAlert = false
      this.showFinalAlert = true
    }

  }
}
</script>

<style lang="scss">
</style>
